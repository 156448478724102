// Import all topic lists from the 'topics' folder
import { mathTopics } from "./topics/mathTopics.js";
import { scienceTopics } from "./topics/scienceTopics.js";
import { techTopics } from "./topics/techTopics.js";
import { engineeringTopics } from "./topics/engineeringTopics.js";
import { socialScienceTopics } from "./topics/socialScienceTopics.js";
import { humanitiesTopics } from "./topics/humanitiesTopics.js";
import { businessTopics } from "./topics/businessTopics.js";
import { languageTopics } from "./topics/languageTopics.js";
import { artsDesignTopics } from "./topics/artsDesignTopics.js";
import { healthTopics } from "./topics/healthTopics.js";
import { lawTopics } from "./topics/lawTopics.js";
import { philosophyTopics } from "./topics/philosophyTopics.js";
import { educationTopics } from "./topics/educationTopics.js";
import { environmentalTopics } from "./topics/environmentalTopics.js";
import { sportsTopics } from "./topics/sportsTopics.js";
import { psychologyTopics } from "./topics/psychologyTopics.js";
import { culturalStudiesTopics } from "./topics/culturalStudiesTopics.js";
import { religionTopics } from "./topics/religionTopics.js";
import { communicationTopics } from "./topics/communicationTopics.js";
import { politicalScienceTopics } from "./topics/politicalScienceTopics.js";

// Combine all topics into one big list
const allTopics = [
  {
    topic: "Mathematics",
    suggestions: mathTopics,
  },
  {
    topic: "Science",
    suggestions: scienceTopics,
  },
  {
    topic: "Technology & Computing",
    suggestions: techTopics,
  },
  {
    topic: "Engineering",
    suggestions: engineeringTopics,
  },
  {
    topic: "Social Sciences",
    suggestions: socialScienceTopics,
  },
  {
    topic: "Humanities",
    suggestions: humanitiesTopics,
  },
  {
    topic: "Business & Economics",
    suggestions: businessTopics,
  },
  {
    topic: "Languages",
    suggestions: languageTopics,
  },
  {
    topic: "Arts & Design",
    suggestions: artsDesignTopics,
  },
  {
    topic: "Health & Medicine",
    suggestions: healthTopics,
  },
  {
    topic: "Law",
    suggestions: lawTopics,
  },
  {
    topic: "Philosophy & Ethics",
    suggestions: philosophyTopics,
  },
  {
    topic: "Education",
    suggestions: educationTopics,
  },
  {
    topic: "Environmental Studies",
    suggestions: environmentalTopics,
  },
  {
    topic: "Sports & Physical Education",
    suggestions: sportsTopics,
  },
  {
    topic: "Psychology & Cognitive Science",
    suggestions: psychologyTopics,
  },
  {
    topic: "Cultural Studies",
    suggestions: culturalStudiesTopics,
  },
  {
    topic: "Religion & Theology",
    suggestions: religionTopics,
  },
  {
    topic: "Communication & Media",
    suggestions: communicationTopics,
  },
  {
    topic: "Political Science & International Relations",
    suggestions: politicalScienceTopics,
  },
];

export default allTopics;
