// Technology & Computing - list of subtopics and related MCQs
export const techTopics = [
  {
    suggestion: "Understand the basics of programming languages",
    sampleMCQ: {
      question: "Which of the following is a programming language?",
      options: ["HTML", "CSS", "Python", "SQL"],
      correctAnswer: "Python",
      explanation:
        "Python is a high-level programming language used for general-purpose programming, while HTML and CSS are markup languages, and SQL is used for database management.",
    },
  },
  {
    suggestion: "Learn about the role of databases in software development",
    sampleMCQ: {
      question:
        "Which type of database is most commonly used for storing relational data?",
      options: ["NoSQL", "SQL", "GraphQL", "Firebase"],
      correctAnswer: "SQL",
      explanation:
        "SQL databases are commonly used for storing relational data because they are designed to manage structured data using relationships between tables.",
    },
  },
  {
    suggestion: "Explore cybersecurity and encryption techniques",
    sampleMCQ: {
      question: "Which of the following is an example of symmetric encryption?",
      options: ["RSA", "AES", "ECC", "DSA"],
      correctAnswer: "AES",
      explanation:
        "AES (Advanced Encryption Standard) is a symmetric encryption algorithm, meaning the same key is used for both encryption and decryption.",
    },
  },
  {
    suggestion: "Understand the fundamentals of cloud computing",
    sampleMCQ: {
      question:
        "Which of the following is a characteristic of cloud computing?",
      options: [
        "On-premises hardware",
        "Scalability on demand",
        "Manual software updates",
        "Physical storage devices",
      ],
      correctAnswer: "Scalability on demand",
      explanation:
        "Cloud computing allows for scalability on demand, meaning you can increase or decrease resources like computing power and storage as needed.",
    },
  },
  {
    suggestion: "Learn about the different types of computer networks",
    sampleMCQ: {
      question:
        "Which of the following is the most common type of local area network?",
      options: ["Ethernet", "Bluetooth", "Wi-Fi", "Fiber optic"],
      correctAnswer: "Ethernet",
      explanation:
        "Ethernet is the most commonly used wired local area network (LAN) technology, providing reliable and fast data transmission over a physical cable.",
    },
  },
  {
    suggestion: "Understand object-oriented programming (OOP) principles",
    sampleMCQ: {
      question:
        "Which of the following is NOT a principle of object-oriented programming?",
      options: ["Encapsulation", "Inheritance", "Polymorphism", "Convergence"],
      correctAnswer: "Convergence",
      explanation:
        "Convergence is not a principle of OOP. The key principles of OOP include encapsulation, inheritance, and polymorphism.",
    },
  },
  {
    suggestion: "Familiarize yourself with version control systems like Git",
    sampleMCQ: {
      question: "What command is used in Git to create a new branch?",
      options: ["git branch", "git checkout", "git merge", "git commit"],
      correctAnswer: "git branch",
      explanation:
        "The 'git branch' command is used to create a new branch in Git, allowing developers to work on separate features or bug fixes without affecting the main codebase.",
    },
  },
  {
    suggestion: "Learn about web development frameworks",
    sampleMCQ: {
      question: "Which of the following is a JavaScript front-end framework?",
      options: ["Django", "Spring", "React", "Laravel"],
      correctAnswer: "React",
      explanation:
        "React is a popular JavaScript front-end framework used for building user interfaces, particularly for single-page applications.",
    },
  },
  {
    suggestion:
      "Understand the difference between front-end and back-end development",
    sampleMCQ: {
      question:
        "Which of the following languages is commonly used for back-end development?",
      options: ["JavaScript", "HTML", "CSS", "Node.js"],
      correctAnswer: "Node.js",
      explanation:
        "Node.js is commonly used for back-end development, allowing developers to write server-side code using JavaScript.",
    },
  },
  {
    suggestion: "Learn about the basics of artificial intelligence (AI)",
    sampleMCQ: {
      question:
        "Which of the following is an application of artificial intelligence?",
      options: [
        "Word processing",
        "Data encryption",
        "Natural language processing",
        "File compression",
      ],
      correctAnswer: "Natural language processing",
      explanation:
        "Natural language processing (NLP) is a field of artificial intelligence focused on enabling computers to understand, interpret, and respond to human language.",
    },
  },
  {
    suggestion: "Understand basic algorithmic concepts",
    sampleMCQ: {
      question: "Which of the following is an example of a sorting algorithm?",
      options: [
        "Breadth-first search",
        "Bubble sort",
        "Dijkstra's algorithm",
        "A* search",
      ],
      correctAnswer: "Bubble sort",
      explanation:
        "Bubble sort is a simple sorting algorithm that repeatedly steps through the list to be sorted, compares adjacent items, and swaps them if they are in the wrong order.",
    },
  },
  {
    suggestion: "Learn about mobile application development",
    sampleMCQ: {
      question:
        "Which framework is commonly used for cross-platform mobile app development?",
      options: ["Flutter", "React Native", "Swift", "Kotlin"],
      correctAnswer: "React Native",
      explanation:
        "React Native is a popular framework for building cross-platform mobile apps using JavaScript and React, allowing for shared code between iOS and Android platforms.",
    },
  },
];
