// Religion - list of subtopics and related MCQs
export const religionTopics = [
  {
    suggestion: "Understand the basic principles of world religions",
    sampleMCQ: {
      question: "Which of the following religions is monotheistic?",
      options: ["Hinduism", "Buddhism", "Christianity", "Shintoism"],
      correctAnswer: "Christianity",
      explanation:
        "Christianity is a monotheistic religion, meaning it believes in one God. Other monotheistic religions include Islam and Judaism, in contrast to polytheistic religions like Hinduism.",
    },
  },
  {
    suggestion:
      "Learn about the concept of the afterlife in different religions",
    sampleMCQ: {
      question: "Which religion believes in the cycle of rebirth, or samsara?",
      options: ["Christianity", "Islam", "Buddhism", "Judaism"],
      correctAnswer: "Buddhism",
      explanation:
        "Buddhism teaches the concept of samsara, the cycle of birth, death, and rebirth, which continues until one attains enlightenment and breaks free from the cycle.",
    },
  },
  {
    suggestion: "Explore the role of sacred texts in religion",
    sampleMCQ: {
      question:
        "Which of the following is the central religious text of Islam?",
      options: ["The Bible", "The Torah", "The Qur'an", "The Vedas"],
      correctAnswer: "The Qur'an",
      explanation:
        "The Qur'an is the central religious text of Islam, believed to be the word of God as revealed to the Prophet Muhammad. It is the primary source of guidance for Muslims.",
    },
  },
  {
    suggestion: "Understand the concept of karma in Eastern religions",
    sampleMCQ: {
      question:
        "Which religion is most closely associated with the concept of karma?",
      options: ["Christianity", "Islam", "Hinduism", "Judaism"],
      correctAnswer: "Hinduism",
      explanation:
        "Karma is a key concept in Hinduism and refers to the idea that one's actions in this life will determine the conditions of future lives. Buddhism and Jainism also emphasize karma.",
    },
  },
  {
    suggestion: "Learn about the major branches of Christianity",
    sampleMCQ: {
      question: "Which branch of Christianity is headed by the Pope?",
      options: [
        "Eastern Orthodox",
        "Protestantism",
        "Roman Catholicism",
        "Anglicanism",
      ],
      correctAnswer: "Roman Catholicism",
      explanation:
        "Roman Catholicism is the largest branch of Christianity and is led by the Pope, who is considered the spiritual leader of Catholics worldwide.",
    },
  },
  {
    suggestion: "Explore the origins of major world religions",
    sampleMCQ: {
      question: "Which of the following religions originated in ancient India?",
      options: ["Islam", "Hinduism", "Christianity", "Judaism"],
      correctAnswer: "Hinduism",
      explanation:
        "Hinduism originated in ancient India and is one of the oldest religions in the world. It encompasses a variety of beliefs and practices, including the worship of many deities.",
    },
  },
  {
    suggestion: "Understand the concept of monotheism vs polytheism",
    sampleMCQ: {
      question: "Which of the following religions is polytheistic?",
      options: ["Islam", "Judaism", "Hinduism", "Christianity"],
      correctAnswer: "Hinduism",
      explanation:
        "Hinduism is a polytheistic religion, meaning it involves the worship of multiple deities. In contrast, Islam, Judaism, and Christianity are monotheistic, worshiping only one God.",
    },
  },
  {
    suggestion: "Learn about religious rituals and their significance",
    sampleMCQ: {
      question:
        "Which of the following is a central ritual in Islam, performed five times a day?",
      options: ["Baptism", "Meditation", "Salat (prayer)", "Pilgrimage"],
      correctAnswer: "Salat (prayer)",
      explanation:
        "Salat is the Islamic ritual of prayer performed five times a day. It is one of the Five Pillars of Islam and serves as a way for Muslims to connect with God.",
    },
  },
  {
    suggestion: "Explore the concept of religious pluralism",
    sampleMCQ: {
      question: "What does religious pluralism advocate?",
      options: [
        "The superiority of one religion over others",
        "The coexistence and acceptance of multiple religions",
        "The merger of all world religions into one",
        "The rejection of all religious beliefs",
      ],
      correctAnswer: "The coexistence and acceptance of multiple religions",
      explanation:
        "Religious pluralism advocates for the peaceful coexistence and mutual respect of multiple religions, recognizing the value of different religious perspectives without promoting one as superior.",
    },
  },
  {
    suggestion: "Understand the role of pilgrimage in various religions",
    sampleMCQ: {
      question:
        "Which city is the destination of the Hajj, the Islamic pilgrimage?",
      options: ["Jerusalem", "Rome", "Mecca", "Varanasi"],
      correctAnswer: "Mecca",
      explanation:
        "Mecca is the holiest city in Islam and the destination of the Hajj pilgrimage, which is one of the Five Pillars of Islam. Every Muslim is required to make this pilgrimage at least once in their lifetime if able.",
    },
  },
  {
    suggestion: "Learn about religious festivals and their significance",
    sampleMCQ: {
      question:
        "Which festival celebrates the resurrection of Jesus Christ in Christianity?",
      options: ["Easter", "Christmas", "Diwali", "Ramadan"],
      correctAnswer: "Easter",
      explanation:
        "Easter is the Christian festival that celebrates the resurrection of Jesus Christ from the dead, considered one of the most important events in Christian theology.",
    },
  },
  {
    suggestion: "Explore the relationship between religion and ethics",
    sampleMCQ: {
      question:
        "Which of the following is an ethical teaching common to many world religions?",
      options: [
        "The pursuit of wealth",
        "Forgiveness and compassion",
        "Religious exclusivity",
        "War and conquest",
      ],
      correctAnswer: "Forgiveness and compassion",
      explanation:
        "Forgiveness and compassion are ethical principles found in many world religions, encouraging followers to treat others with kindness, understanding, and mercy.",
    },
  },
  {
    suggestion: "Understand the role of prophets in religious traditions",
    sampleMCQ: {
      question: "Which prophet is central to the Islamic faith?",
      options: ["Jesus", "Moses", "Muhammad", "Buddha"],
      correctAnswer: "Muhammad",
      explanation:
        "The Prophet Muhammad is central to the Islamic faith, believed to be the last and final prophet through whom God revealed the Qur'an. He is highly revered in Islam.",
    },
  },
  {
    suggestion: "Learn about indigenous religions and spiritual traditions",
    sampleMCQ: {
      question:
        "Which of the following is commonly associated with indigenous spiritual traditions?",
      options: [
        "Monotheism",
        "Shamanism",
        "Religious texts",
        "Formalized priesthood",
      ],
      correctAnswer: "Shamanism",
      explanation:
        "Shamanism is often associated with indigenous spiritual traditions, where a shaman or spiritual leader is believed to have the ability to communicate with the spirit world and mediate between humans and nature.",
    },
  },
  {
    suggestion: "Explore the impact of religion on society and culture",
    sampleMCQ: {
      question:
        "Which term describes the influence of religion on shaping moral values and social norms?",
      options: [
        "Secularism",
        "Religious pluralism",
        "Theocracy",
        "Religious socialization",
      ],
      correctAnswer: "Religious socialization",
      explanation:
        "Religious socialization refers to the process by which individuals are taught and influenced by religious beliefs, moral values, and social norms, shaping their behavior and worldview.",
    },
  },
];
