import React, { useEffect } from "react";
import { Box, Typography, TextField } from "@mui/material";
import QuizSyncText from "./QuizSyncText";

const Join = ({ initiateQuizJoin, quizCode, setQuizCode }) => {
  useEffect(() => {
    setQuizCode(""); // Reset quizCode to an empty string when component mounts
  }, [setQuizCode]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Join <QuizSyncText>QuizSync</QuizSyncText> Session
      </Typography>
      <Box sx={{ display: "block", width: "100%", maxWidth: 450 }}>
        <Box display="flex" justifyContent="center" gap={2}>
          {[0, 1, 2, 3].map((num) => (
            <TextField
              key={num}
              inputProps={{
                maxLength: 1,
                inputMode: "numeric", // Ensure a numeric keypad appears on mobile
                pattern: "[0-9]*", // Only allow digits
                style: {
                  fontWeight: "bold",
                  fontSize: "20px",
                  letterSpacing: "0.5em",
                  textAlign: "center",
                },
              }}
              margin="normal"
              size="medium"
              value={quizCode[num] || ""}
              onChange={(e) => {
                const newQuizCode = quizCode.split("");
                newQuizCode[num] = e.target.value.toUpperCase();
                setQuizCode(newQuizCode.join(""));

                // Move to the next input if not the last
                if (e.target.value && num < 3) {
                  document.getElementById(`code-input-${num + 1}`).focus();
                }

                // Automatically join quiz when the last input box is filled
                if (num === 3 && e.target.value) {
                  initiateQuizJoin(newQuizCode.join(""));
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter" && num === 3) {
                  initiateQuizJoin(quizCode);
                }
              }}
              autoComplete="off"
              id={`code-input-${num}`}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Join;
