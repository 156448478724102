import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  Card,
  CardContent,
  List,
  Skeleton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const ReviewQuestions = ({
  socket,
  participantId,
  searchQuery,
  title = "",
  editableQuestions = [],
  isAddingQuestion = false,
  additionalPrompt = "",
  setAdditionalPrompt,
  quizId = "",
  loadedQuizId = "",
  setEditableQuestions,
  setIsAddingQuestion,
}) => {
  const [editMode, setEditMode] = useState(true);

  const handleCreateQuizSession = useCallback(() => {
    if (!participantId) return;
    socket.emit("createQuizSession", {
      quizId,
      participantId,
      questions: editableQuestions,
      title,
      topic: searchQuery,
    });
  }, [socket, quizId, participantId, editableQuestions, title, searchQuery]);

  // Handle switching between render and edit mode
  const handleModeSwitch = () => {
    setEditMode(!editMode);
  };

  const handleQuestionChange = useCallback(
    (index, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[index].question = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const saveQuiz = useCallback(() => {
    const formattedQuestions = editableQuestions.map((question, index) => ({
      id: question.id,
      order: index,
      contentId: question.contentId,
      type: question.type,
      data: {
        question: question.question,
        options: question.options,
        correctAnswer: question.correctAnswer,
        explanation: question.explanation,
      },
    }));
    socket.emit("saveQuiz", {
      quizId,
      questions: formattedQuestions,
      title,
      topic: searchQuery,
      participantId: participantId,
    });
  }, [socket, quizId, editableQuestions, title, searchQuery]);

  const handleOptionChange = useCallback(
    (qIndex, oIndex, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[qIndex].options[oIndex] = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleCorrectAnswerChange = useCallback(
    (index, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[index].correctAnswer = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleExplanationChange = useCallback(
    (index, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[index].explanation = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const requestAddQuestion = useCallback(() => {
    if (!quizId) return;
    setIsAddingQuestion(true);
    socket.emit("generateSingleQuestion", {
      quizId,
      currentQuestions: editableQuestions,
      additionalPrompt,
    });
  }, [
    socket,
    setIsAddingQuestion,
    quizId,
    editableQuestions,
    additionalPrompt,
  ]);

  const handleDeleteQuestion = useCallback(
    (index) => {
      const newQuestions = editableQuestions.filter(
        (_, qIndex) => qIndex !== index,
      );
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleAddNewQuestion = () => {
    if (additionalPrompt && additionalPrompt.trim()) {
      requestAddQuestion();
      setAdditionalPrompt("");
    }
  };

  const moveArrayItem = (arr, fromIndex, toIndex) => {
    if (toIndex >= 0 && toIndex < arr.length) {
      const newArr = [...arr];
      const [movedItem] = newArr.splice(fromIndex, 1);
      newArr.splice(toIndex, 0, movedItem);
      return newArr;
    }
    return arr;
  };

  const handleMoveQuestionUp = (contentId) => {
    const index = editableQuestions.findIndex((q) => q.contentId === contentId);
    if (index > 0) {
      setEditableQuestions((prevQuestions) =>
        moveArrayItem(prevQuestions, index, index - 1),
      );
    }
  };

  const handleMoveQuestionDown = (contentId) => {
    const index = editableQuestions.findIndex((q) => q.contentId === contentId);
    if (index < editableQuestions.length - 1) {
      setEditableQuestions((prevQuestions) =>
        moveArrayItem(prevQuestions, index, index + 1),
      );
    }
  };

  useEffect(() => {
    socket.on(
      "singleQuestionGenerationSuccess",
      ({ quizId, question, contentId, type }) => {
        setEditableQuestions((prevQuestions) => [
          ...prevQuestions,
          { ...question, contentId, type },
        ]);
        setIsAddingQuestion(false);
      },
    );
    return () => {
      socket.off("singleQuestionGenerationSuccess");
    };
  }, [socket, quizId, setEditableQuestions, setIsAddingQuestion]);

  useEffect(() => {
    if (editableQuestions.length > 0) {
      saveQuiz();
    }
  }, [socket, editableQuestions, saveQuiz]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      saveQuiz();
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [editableQuestions, saveQuiz]);

  return (
    <Box sx={{ padding: 0, mt: -2, width: "100%", maxWidth: "800px" }}>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography variant="h6" fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Quiz ID: {quizId || loadedQuizId}
        </Typography>
      </Box>

      {/* Mode Switch */}
      <FormControlLabel
        control={
          <Switch
            checked={editMode}
            onChange={handleModeSwitch}
            color="primary"
          />
        }
        label={editMode ? "Edit Mode" : "Render Mode"}
      />

      {editMode ? (
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            mb={2}
          >
            <IconButton
              color="primary"
              onClick={() =>
                handleCreateQuizSession({
                  title,
                  questions: editableQuestions,
                })
              }
            >
              <AddIcon />
            </IconButton>
          </Box>

          <List sx={{ width: "100%" }}>
            {editableQuestions.map((question, qIndex) => (
              <Accordion
                key={`question-${qIndex}`}
                sx={{
                  marginBottom: 2,
                  width: "100%",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`question-${qIndex}-content`}
                  id={`question-${qIndex}-header`}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography variant="h6">{`Q${qIndex + 1}: ${question.question}`}</Typography>
                    <Box>
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDeleteQuestion(qIndex);
                        }}
                        size="small"
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          handleMoveQuestionUp(question.contentId);
                        }}
                        size="small"
                        disabled={qIndex === 0}
                      >
                        <ArrowUpwardIcon />
                      </IconButton>
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          handleMoveQuestionDown(question.contentId);
                        }}
                        size="small"
                        disabled={qIndex === editableQuestions.length - 1}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <CardContent>
                    {/* Editable mode */}
                    <TextField
                      label={`Question ${qIndex + 1}`}
                      value={question.question}
                      onChange={(event) => handleQuestionChange(qIndex, event)}
                      fullWidth
                      margin="normal"
                    />
                    {question.options.map((option, oIndex) => (
                      <TextField
                        key={`question-${qIndex}-option-${oIndex}`}
                        label={`Option ${oIndex + 1}`}
                        value={option}
                        onChange={(event) =>
                          handleOptionChange(qIndex, oIndex, event)
                        }
                        fullWidth
                        margin="normal"
                      />
                    ))}
                    <TextField
                      label="Correct Answer"
                      value={question.correctAnswer}
                      onChange={(event) =>
                        handleCorrectAnswerChange(qIndex, event)
                      }
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Explanation"
                      value={question.explanation}
                      onChange={(event) =>
                        handleExplanationChange(qIndex, event)
                      }
                      fullWidth
                      margin="normal"
                    />
                  </CardContent>
                </AccordionDetails>
              </Accordion>
            ))}
            {isAddingQuestion && (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={80}
                sx={{ marginBottom: 2 }}
              />
            )}

            <Card sx={{ marginBottom: 2, width: "100%" }}>
              <CardContent>
                <Typography variant="h6">Add a New Question</Typography>
                <TextField
                  label="New Question"
                  value={additionalPrompt}
                  onChange={(event) => setAdditionalPrompt(event.target.value)}
                  fullWidth
                  margin="normal"
                />
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddNewQuestion}
                  disabled={!additionalPrompt.trim()}
                >
                  Add Question
                </Button>
              </CardContent>
            </Card>
          </List>
        </Box>
      ) : (
        // Render Mode
        <Box>
          <List sx={{ width: "100%" }}>
            {editableQuestions.map((question, qIndex) => (
              <Accordion
                key={`question-render-${qIndex}`}
                sx={{
                  marginBottom: 2,
                  width: "100%",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`question-render-${qIndex}-content`}
                  id={`question-render-${qIndex}-header`}
                >
                  <Typography variant="h6">{`Q${qIndex + 1}: ${question.question}`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CardContent>
                    {/* Rendered as MCQ */}
                    {question.options.map((option, oIndex) => (
                      <Button
                        key={`render-option-${qIndex}-${oIndex}`}
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: 1 }}
                      >
                        {option}
                      </Button>
                    ))}
                  </CardContent>
                </AccordionDetails>
              </Accordion>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default ReviewQuestions;
