// Environmental Studies - list of subtopics and related MCQs
export const environmentalTopics = [
  {
    suggestion: "Understand the basics of ecosystems",
    sampleMCQ: {
      question:
        "What term refers to the living and non-living components interacting in a specific area?",
      options: ["Biome", "Habitat", "Ecosystem", "Niche"],
      correctAnswer: "Ecosystem",
      explanation:
        "An ecosystem consists of both living organisms (biotic components) and non-living components (abiotic components) interacting in a specific environment.",
    },
  },
  {
    suggestion: "Learn about biodiversity and its importance",
    sampleMCQ: {
      question: "Which of the following best defines biodiversity?",
      options: [
        "The number of ecosystems in an area",
        "The variety of life in a particular habitat or ecosystem",
        "The availability of resources in an ecosystem",
        "The interactions between organisms",
      ],
      correctAnswer: "The variety of life in a particular habitat or ecosystem",
      explanation:
        "Biodiversity refers to the variety of living organisms within an ecosystem, including the diversity of species, genetic variation, and ecosystem diversity.",
    },
  },
  {
    suggestion: "Explore the concept of climate change",
    sampleMCQ: {
      question: "Which gas is the primary contributor to global warming?",
      options: ["Oxygen", "Carbon dioxide", "Nitrogen", "Helium"],
      correctAnswer: "Carbon dioxide",
      explanation:
        "Carbon dioxide (CO2) is a greenhouse gas that traps heat in the Earth's atmosphere, making it one of the primary contributors to global warming.",
    },
  },
  {
    suggestion: "Learn about renewable and non-renewable energy sources",
    sampleMCQ: {
      question: "Which of the following is a renewable energy source?",
      options: ["Coal", "Natural gas", "Solar energy", "Nuclear energy"],
      correctAnswer: "Solar energy",
      explanation:
        "Solar energy is a renewable energy source, meaning it is naturally replenished and sustainable over time, unlike fossil fuels such as coal and natural gas.",
    },
  },
  {
    suggestion: "Understand the impact of deforestation",
    sampleMCQ: {
      question: "What is the main consequence of deforestation?",
      options: [
        "Increased biodiversity",
        "Increased carbon sequestration",
        "Loss of habitat for wildlife",
        "Reduction in atmospheric CO2 levels",
      ],
      correctAnswer: "Loss of habitat for wildlife",
      explanation:
        "Deforestation leads to the loss of habitats for countless species, reducing biodiversity and disrupting ecosystems. It also contributes to climate change by releasing stored carbon.",
    },
  },
  {
    suggestion: "Learn about water pollution and its effects",
    sampleMCQ: {
      question: "Which of the following is a major cause of water pollution?",
      options: [
        "Deforestation",
        "Fossil fuel emissions",
        "Industrial waste disposal",
        "Solar energy production",
      ],
      correctAnswer: "Industrial waste disposal",
      explanation:
        "Industrial waste disposal, including chemicals and toxins released into rivers, lakes, and oceans, is a major cause of water pollution, affecting both aquatic life and human health.",
    },
  },
  {
    suggestion: "Explore the concept of sustainable development",
    sampleMCQ: {
      question:
        "Which of the following best describes sustainable development?",
      options: [
        "Development that meets the needs of the present without compromising the ability of future generations to meet their own needs",
        "Rapid industrialization to boost economic growth",
        "Development focused on short-term resource extraction",
        "Economic development without regard for environmental impacts",
      ],
      correctAnswer:
        "Development that meets the needs of the present without compromising the ability of future generations to meet their own needs",
      explanation:
        "Sustainable development is about meeting the needs of the present while ensuring that future generations can also meet their needs, emphasizing long-term environmental, economic, and social sustainability.",
    },
  },
  {
    suggestion: "Learn about the greenhouse effect and global warming",
    sampleMCQ: {
      question:
        "Which process describes the trapping of heat in Earth's atmosphere by greenhouse gases?",
      options: [
        "Ozone depletion",
        "Greenhouse effect",
        "Acid rain formation",
        "Global dimming",
      ],
      correctAnswer: "Greenhouse effect",
      explanation:
        "The greenhouse effect is the process by which greenhouse gases, such as carbon dioxide and methane, trap heat in Earth's atmosphere, contributing to global warming.",
    },
  },
  {
    suggestion:
      "Understand the significance of environmental laws and regulations",
    sampleMCQ: {
      question:
        "Which of the following is an international agreement aimed at combating climate change?",
      options: [
        "Kyoto Protocol",
        "Paris Agreement",
        "Montreal Protocol",
        "Geneva Convention",
      ],
      correctAnswer: "Paris Agreement",
      explanation:
        "The Paris Agreement is an international treaty aimed at reducing greenhouse gas emissions and limiting global warming to well below 2°C, with efforts to keep it to 1.5°C.",
    },
  },
  {
    suggestion: "Explore the impact of plastic pollution on marine ecosystems",
    sampleMCQ: {
      question: "What is the main threat posed by plastic pollution in oceans?",
      options: [
        "Increased fish population",
        "Improved water quality",
        "Harm to marine life and ecosystems",
        "Reduced algae growth",
      ],
      correctAnswer: "Harm to marine life and ecosystems",
      explanation:
        "Plastic pollution in oceans poses a significant threat to marine life, causing harm through ingestion, entanglement, and habitat degradation, while also disrupting ecosystems.",
    },
  },
  {
    suggestion:
      "Learn about the role of conservation in preserving natural resources",
    sampleMCQ: {
      question: "Which of the following is a primary goal of conservation?",
      options: [
        "Increasing industrial production",
        "Maximizing resource extraction",
        "Sustaining biodiversity and protecting natural habitats",
        "Expanding urban development",
      ],
      correctAnswer: "Sustaining biodiversity and protecting natural habitats",
      explanation:
        "Conservation efforts aim to sustain biodiversity and protect natural habitats to ensure the long-term survival of species and ecosystems, while maintaining ecological balance.",
    },
  },
  {
    suggestion:
      "Understand the role of waste management in reducing environmental impact",
    sampleMCQ: {
      question: "Which of the following is a key strategy in waste management?",
      options: [
        "Increasing landfill use",
        "Promoting waste incineration",
        "Reducing, reusing, and recycling materials",
        "Maximizing waste disposal in oceans",
      ],
      correctAnswer: "Reducing, reusing, and recycling materials",
      explanation:
        "The key strategy in waste management involves reducing the amount of waste produced, reusing materials, and recycling to minimize the environmental impact of waste disposal.",
    },
  },
  {
    suggestion: "Explore the effects of air pollution on human health",
    sampleMCQ: {
      question:
        "Which air pollutant is most commonly associated with respiratory diseases?",
      options: ["Ozone", "Carbon dioxide", "Sulfur dioxide", "Nitrogen"],
      correctAnswer: "Sulfur dioxide",
      explanation:
        "Sulfur dioxide (SO2) is a major air pollutant that contributes to respiratory problems, including asthma and bronchitis, and is commonly released by burning fossil fuels.",
    },
  },
  {
    suggestion: "Learn about the impact of overfishing on marine ecosystems",
    sampleMCQ: {
      question: "Which of the following is a consequence of overfishing?",
      options: [
        "Increase in fish populations",
        "Improvement in marine biodiversity",
        "Depletion of fish stocks and disruption of ecosystems",
        "Increase in coral reef growth",
      ],
      correctAnswer: "Depletion of fish stocks and disruption of ecosystems",
      explanation:
        "Overfishing leads to the depletion of fish stocks, which disrupts marine ecosystems and negatively impacts biodiversity by removing key species from the food chain.",
    },
  },
];
