// Health & Medicine - list of subtopics and related MCQs
export const healthTopics = [
  {
    suggestion: "Understand the basics of human anatomy",
    sampleMCQ: {
      question:
        "Which organ is responsible for pumping blood throughout the body?",
      options: ["Liver", "Lungs", "Heart", "Kidneys"],
      correctAnswer: "Heart",
      explanation:
        "The heart is responsible for pumping blood throughout the body, supplying oxygen and nutrients to tissues and removing waste products.",
    },
  },
  {
    suggestion: "Learn about the functions of the human immune system",
    sampleMCQ: {
      question:
        "Which type of cell is primarily responsible for fighting infections?",
      options: ["Red blood cells", "Platelets", "White blood cells", "Neurons"],
      correctAnswer: "White blood cells",
      explanation:
        "White blood cells (leukocytes) are key components of the immune system, responsible for detecting and fighting infections and foreign invaders in the body.",
    },
  },
  {
    suggestion: "Explore the importance of nutrition and a balanced diet",
    sampleMCQ: {
      question: "Which of the following is considered a macronutrient?",
      options: ["Vitamins", "Minerals", "Protein", "Water"],
      correctAnswer: "Protein",
      explanation:
        "Proteins are macronutrients, along with carbohydrates and fats, that provide the body with energy and support essential functions like tissue growth and repair.",
    },
  },
  {
    suggestion: "Understand the basics of mental health",
    sampleMCQ: {
      question: "Which of the following is a common symptom of depression?",
      options: [
        "Excessive happiness",
        "Increased energy",
        "Persistent sadness",
        "Improved sleep",
      ],
      correctAnswer: "Persistent sadness",
      explanation:
        "Persistent sadness, along with feelings of hopelessness and loss of interest in activities, are common symptoms of depression, a mental health condition that affects mood and behavior.",
    },
  },
  {
    suggestion: "Learn about the circulatory system",
    sampleMCQ: {
      question: "What is the main function of red blood cells?",
      options: [
        "To fight infections",
        "To transport oxygen",
        "To aid in blood clotting",
        "To produce antibodies",
      ],
      correctAnswer: "To transport oxygen",
      explanation:
        "Red blood cells are responsible for transporting oxygen from the lungs to the rest of the body and returning carbon dioxide to the lungs for exhalation.",
    },
  },
  {
    suggestion: "Explore the basics of first aid and emergency care",
    sampleMCQ: {
      question:
        "What is the first step in CPR (Cardiopulmonary Resuscitation)?",
      options: [
        "Checking the pulse",
        "Giving chest compressions",
        "Opening the airway",
        "Delivering rescue breaths",
      ],
      correctAnswer: "Opening the airway",
      explanation:
        "The first step in CPR is to open the airway to check if the person is breathing. If they are not breathing, chest compressions and rescue breaths should be administered.",
    },
  },
  {
    suggestion: "Understand the basics of chronic diseases",
    sampleMCQ: {
      question: "Which of the following is an example of a chronic disease?",
      options: ["Influenza", "Hypertension", "Appendicitis", "Strep throat"],
      correctAnswer: "Hypertension",
      explanation:
        "Hypertension (high blood pressure) is a chronic disease, meaning it lasts for a long time and requires ongoing management. It increases the risk of heart disease and stroke.",
    },
  },
  {
    suggestion: "Learn about the reproductive system",
    sampleMCQ: {
      question:
        "Which hormone plays a key role in regulating the female menstrual cycle?",
      options: ["Testosterone", "Insulin", "Estrogen", "Cortisol"],
      correctAnswer: "Estrogen",
      explanation:
        "Estrogen is a hormone that plays a key role in regulating the female menstrual cycle, reproductive health, and secondary sexual characteristics.",
    },
  },
  {
    suggestion: "Understand the importance of physical activity and exercise",
    sampleMCQ: {
      question: "Which type of exercise improves cardiovascular health?",
      options: ["Yoga", "Weight lifting", "Aerobic exercise", "Stretching"],
      correctAnswer: "Aerobic exercise",
      explanation:
        "Aerobic exercise, such as running, swimming, or cycling, improves cardiovascular health by strengthening the heart and lungs, and enhancing circulation.",
    },
  },
  {
    suggestion: "Learn about infectious diseases and prevention",
    sampleMCQ: {
      question: "Which of the following is a viral infection?",
      options: ["Tuberculosis", "Hepatitis B", "Malaria", "Strep throat"],
      correctAnswer: "Hepatitis B",
      explanation:
        "Hepatitis B is a viral infection that affects the liver, and it can be transmitted through contact with infectious body fluids, such as blood.",
    },
  },
  {
    suggestion: "Understand the impact of stress on health",
    sampleMCQ: {
      question:
        "Which hormone is commonly associated with the body's stress response?",
      options: ["Cortisol", "Dopamine", "Serotonin", "Insulin"],
      correctAnswer: "Cortisol",
      explanation:
        "Cortisol is a hormone released by the adrenal glands in response to stress. It helps regulate metabolism, inflammation, and blood pressure during stressful situations.",
    },
  },
  {
    suggestion: "Learn about sleep and its role in overall health",
    sampleMCQ: {
      question:
        "How many hours of sleep are recommended for most adults each night?",
      options: ["4-5 hours", "6-7 hours", "7-9 hours", "9-10 hours"],
      correctAnswer: "7-9 hours",
      explanation:
        "Most adults are recommended to get 7-9 hours of sleep per night to maintain optimal health, cognitive function, and overall well-being.",
    },
  },
  {
    suggestion: "Explore the role of vaccination in preventing disease",
    sampleMCQ: {
      question:
        "Which of the following diseases can be prevented through vaccination?",
      options: ["Diabetes", "Measles", "Hypertension", "Arthritis"],
      correctAnswer: "Measles",
      explanation:
        "Measles is a highly contagious viral infection that can be effectively prevented through vaccination, which helps the immune system recognize and fight the virus.",
    },
  },
  {
    suggestion: "Understand the basics of the digestive system",
    sampleMCQ: {
      question: "Which organ is primarily responsible for nutrient absorption?",
      options: ["Stomach", "Liver", "Small intestine", "Large intestine"],
      correctAnswer: "Small intestine",
      explanation:
        "The small intestine is primarily responsible for absorbing nutrients from digested food into the bloodstream, playing a critical role in digestion.",
    },
  },
  {
    suggestion: "Learn about mental health disorders and treatment options",
    sampleMCQ: {
      question: "Which therapy is commonly used to treat anxiety disorders?",
      options: [
        "Cognitive Behavioral Therapy (CBT)",
        "Physical therapy",
        "Occupational therapy",
        "Art therapy",
      ],
      correctAnswer: "Cognitive Behavioral Therapy (CBT)",
      explanation:
        "Cognitive Behavioral Therapy (CBT) is a type of psychotherapy that helps individuals identify and change negative thought patterns and behaviors, commonly used to treat anxiety disorders.",
    },
  },
];
