export const mathTopics = [
  {
    suggestion: "Study Arithmetic",
    sampleMCQ: {
      question: "What is the value of 15 ÷ 3?",
      options: ["3", "4", "5", "6"],
      correctAnswer: "5",
      explanation: "15 divided by 3 equals 5.",
    },
  },
  {
    suggestion: "Learn Algebra",
    sampleMCQ: {
      question: "What is the value of x in the equation 4x - 5 = 11?",
      options: ["x = 4", "x = 6", "x = 3", "x = 5"],
      correctAnswer: "x = 4",
      explanation: "Solving 4x - 5 = 11 by isolating x gives x = 4.",
    },
  },
  {
    suggestion: "Understand Geometry",
    sampleMCQ: {
      question: "What is the area of a rectangle with length 5 and width 4?",
      options: ["20", "18", "12", "25"],
      correctAnswer: "20",
      explanation: "The area of a rectangle is calculated by length × width.",
    },
  },
  {
    suggestion: "Learn Calculus",
    sampleMCQ: {
      question: "What is the derivative of x²?",
      options: ["2x", "x", "x²", "x³"],
      correctAnswer: "2x",
      explanation: "The derivative of x² with respect to x is 2x.",
    },
  },
  {
    suggestion: "Study Probability and Statistics",
    sampleMCQ: {
      question:
        "If a coin is flipped, what is the probability of getting heads?",
      options: ["1/2", "1/3", "1/4", "1"],
      correctAnswer: "1/2",
      explanation: "A fair coin has two sides, so the probability is 1/2.",
    },
  },
  {
    suggestion: "Understand Number Theory",
    sampleMCQ: {
      question: "What is the smallest prime number?",
      options: ["2", "1", "3", "5"],
      correctAnswer: "2",
      explanation: "2 is the smallest prime number.",
    },
  },
  {
    suggestion: "Learn Linear Algebra",
    sampleMCQ: {
      question: "What is the determinant of the matrix [[1, 3], [2, 4]]?",
      options: ["-2", "2", "0", "1"],
      correctAnswer: "-2",
      explanation: "The determinant is calculated as (1×4) - (3×2) = -2.",
    },
  },
  {
    suggestion: "Study Set Theory",
    sampleMCQ: {
      question: "What is the union of sets {1, 2} and {2, 3}?",
      options: ["{1, 2, 3}", "{2}", "{1, 3}", "{1}"],
      correctAnswer: "{1, 2, 3}",
      explanation: "The union includes all elements from both sets.",
    },
  },
  {
    suggestion: "Learn Combinatorics",
    sampleMCQ: {
      question: "How many ways can you arrange 4 letters A, B, C, and D?",
      options: ["24", "12", "16", "8"],
      correctAnswer: "24",
      explanation:
        "The number of permutations of 4 distinct objects is 4! = 24.",
    },
  },
  {
    suggestion: "Understand Discrete Mathematics",
    sampleMCQ: {
      question: "Which is a tautology in propositional logic?",
      options: ["P ∨ ¬P", "P ∧ ¬P", "P", "¬P"],
      correctAnswer: "P ∨ ¬P",
      explanation: "This is a basic tautology, meaning it's always true.",
    },
  },
  {
    suggestion: "Study Mathematical Logic",
    sampleMCQ: {
      question: "Which is a valid example of proof by contradiction?",
      options: [
        "Assume the negation is true and reach a contradiction.",
        "Test all cases.",
        "Use induction.",
        "Derive directly.",
      ],
      correctAnswer: "Assume the negation is true and reach a contradiction.",
      explanation: "This is the essence of proof by contradiction.",
    },
  },
  {
    suggestion: "Learn Differential Equations",
    sampleMCQ: {
      question: "What is the general solution to the equation dy/dx = 2x?",
      options: ["y = x² + C", "y = x²", "y = 2x + C", "y = x + 2"],
      correctAnswer: "y = x² + C",
      explanation:
        "The solution to the differential equation is the integral of 2x, which is x² + C.",
    },
  },
  {
    suggestion: "Understand Topology",
    sampleMCQ: {
      question: "Which of the following is an open set in topology?",
      options: [
        "The interior of a circle",
        "The surface of a sphere",
        "A closed interval",
        "A point",
      ],
      correctAnswer: "The interior of a circle",
      explanation: "Open sets do not include their boundary in topology.",
    },
  },
  {
    suggestion: "Learn Mathematical Analysis",
    sampleMCQ: {
      question: "What is the limit of 1/x as x approaches infinity?",
      options: ["0", "1", "Infinity", "-1"],
      correctAnswer: "0",
      explanation: "As x approaches infinity, 1/x gets closer to 0.",
    },
  },
  {
    suggestion: "Study Abstract Algebra",
    sampleMCQ: {
      question: "What is the identity element for addition in a group?",
      options: ["0", "1", "-1", "2"],
      correctAnswer: "0",
      explanation: "The identity element for addition is 0, because x + 0 = x.",
    },
  },
  {
    suggestion: "Understand Applied Mathematics",
    sampleMCQ: {
      question: "Which equation best describes linear programming?",
      options: [
        "Optimization of a linear objective function",
        "Solving quadratic equations",
        "Integrating functions",
        "Using determinants",
      ],
      correctAnswer: "Optimization of a linear objective function",
      explanation:
        "Linear programming involves optimizing a linear objective function subject to constraints.",
    },
  },
  {
    suggestion: "Learn Mathematical Physics",
    sampleMCQ: {
      question: "What is the formula for force in Newton's second law?",
      options: ["F = ma", "F = mv", "F = mg", "F = qE"],
      correctAnswer: "F = ma",
      explanation:
        "Newton's second law states that force equals mass times acceleration.",
    },
  },
  {
    suggestion: "Study Computational Mathematics",
    sampleMCQ: {
      question: "What is the binary representation of the decimal number 5?",
      options: ["101", "100", "111", "110"],
      correctAnswer: "101",
      explanation: "The binary representation of 5 is 101.",
    },
  },
  {
    suggestion: "Learn Game Theory",
    sampleMCQ: {
      question: "What is a Nash equilibrium?",
      options: [
        "A situation where no player can improve their outcome by changing strategies",
        "The highest possible outcome",
        "A lose-lose situation",
        "The first move in a game",
      ],
      correctAnswer:
        "A situation where no player can improve their outcome by changing strategies",
      explanation:
        "In a Nash equilibrium, each player's strategy is optimal given the strategies of the others.",
    },
  },
  {
    suggestion: "Study Fractal Geometry",
    sampleMCQ: {
      question: "Which of the following is an example of a fractal?",
      options: ["Mandelbrot set", "Square", "Line", "Circle"],
      correctAnswer: "Mandelbrot set",
      explanation: "The Mandelbrot set is a famous fractal.",
    },
  },
];
