// Psychology - list of subtopics and related MCQs
export const psychologyTopics = [
  {
    suggestion: "Understand the basics of cognitive psychology",
    sampleMCQ: {
      question:
        "Which of the following processes is primarily studied in cognitive psychology?",
      options: ["Perception", "Emotions", "Motivation", "Social behavior"],
      correctAnswer: "Perception",
      explanation:
        "Cognitive psychology focuses on internal processes such as perception, memory, problem-solving, and language, which are essential for understanding how we think and process information.",
    },
  },
  {
    suggestion: "Learn about the stages of human development",
    sampleMCQ: {
      question:
        "Which psychologist is known for his theory of cognitive development in children?",
      options: ["Sigmund Freud", "Erik Erikson", "Jean Piaget", "B.F. Skinner"],
      correctAnswer: "Jean Piaget",
      explanation:
        "Jean Piaget is known for his theory of cognitive development, which outlines how children progress through stages of learning and understanding as they grow.",
    },
  },
  {
    suggestion: "Explore behavioral psychology and learning",
    sampleMCQ: {
      question:
        "Which of the following is an example of classical conditioning?",
      options: [
        "Teaching a child to ride a bicycle",
        "A dog salivating when it hears a bell associated with food",
        "Praising an employee for good work",
        "Learning to solve a math problem",
      ],
      correctAnswer:
        "A dog salivating when it hears a bell associated with food",
      explanation:
        "Classical conditioning, as demonstrated by Ivan Pavlov, occurs when a neutral stimulus (like a bell) becomes associated with an unconditioned stimulus (like food) to elicit a conditioned response (like salivation).",
    },
  },
  {
    suggestion: "Understand the different types of memory",
    sampleMCQ: {
      question:
        "Which type of memory is used to store information for a few seconds to a minute?",
      options: [
        "Short-term memory",
        "Long-term memory",
        "Sensory memory",
        "Procedural memory",
      ],
      correctAnswer: "Short-term memory",
      explanation:
        "Short-term memory holds information temporarily, typically for a few seconds to a minute, before it is either forgotten or transferred to long-term memory.",
    },
  },
  {
    suggestion: "Learn about personality theories",
    sampleMCQ: {
      question:
        "Which psychologist developed the theory of the unconscious mind and psychoanalysis?",
      options: ["Carl Rogers", "Abraham Maslow", "Sigmund Freud", "Carl Jung"],
      correctAnswer: "Sigmund Freud",
      explanation:
        "Sigmund Freud developed the theory of psychoanalysis, which emphasizes the influence of the unconscious mind on behavior, including concepts like the id, ego, and superego.",
    },
  },
  {
    suggestion: "Explore the concept of motivation and its theories",
    sampleMCQ: {
      question:
        "Which theory suggests that humans are motivated by a hierarchy of needs?",
      options: [
        "Drive-reduction theory",
        "Incentive theory",
        "Maslow’s hierarchy of needs",
        "Cognitive dissonance theory",
      ],
      correctAnswer: "Maslow’s hierarchy of needs",
      explanation:
        "Maslow’s hierarchy of needs suggests that human motivation is driven by a progression of needs, starting with physiological needs and advancing to safety, love, esteem, and self-actualization.",
    },
  },
  {
    suggestion: "Understand the basics of social psychology",
    sampleMCQ: {
      question:
        "Which experiment demonstrated the power of authority and obedience in social psychology?",
      options: [
        "Stanford prison experiment",
        "Milgram’s obedience experiment",
        "Pavlov’s dog experiment",
        "Piaget’s conservation task",
      ],
      correctAnswer: "Milgram’s obedience experiment",
      explanation:
        "Milgram’s obedience experiment demonstrated the power of authority, showing that participants were willing to administer harmful electric shocks to others under the instruction of an authority figure.",
    },
  },
  {
    suggestion: "Learn about abnormal psychology and mental health disorders",
    sampleMCQ: {
      question: "Which of the following is classified as an anxiety disorder?",
      options: [
        "Bipolar disorder",
        "Schizophrenia",
        "Obsessive-compulsive disorder",
        "Dissociative identity disorder",
      ],
      correctAnswer: "Obsessive-compulsive disorder",
      explanation:
        "Obsessive-compulsive disorder (OCD) is an anxiety disorder characterized by unwanted, intrusive thoughts (obsessions) and repetitive behaviors (compulsions).",
    },
  },
  {
    suggestion: "Explore the fundamentals of emotional intelligence",
    sampleMCQ: {
      question: "What is emotional intelligence primarily concerned with?",
      options: [
        "Academic performance",
        "Managing and understanding emotions",
        "Physical health",
        "Creativity",
      ],
      correctAnswer: "Managing and understanding emotions",
      explanation:
        "Emotional intelligence refers to the ability to perceive, control, and evaluate emotions in oneself and others, and to use this understanding to guide behavior and decision-making.",
    },
  },
  {
    suggestion: "Understand the process of sensation and perception",
    sampleMCQ: {
      question:
        "Which of the following refers to the process of interpreting sensory information?",
      options: ["Sensation", "Perception", "Cognition", "Memory"],
      correctAnswer: "Perception",
      explanation:
        "Perception is the process of organizing, interpreting, and making sense of sensory information, allowing individuals to understand their environment.",
    },
  },
  {
    suggestion: "Learn about developmental psychology and life stages",
    sampleMCQ: {
      question:
        "Which psychologist proposed eight stages of psychosocial development across the lifespan?",
      options: [
        "Jean Piaget",
        "Erik Erikson",
        "Sigmund Freud",
        "Lawrence Kohlberg",
      ],
      correctAnswer: "Erik Erikson",
      explanation:
        "Erik Erikson proposed eight stages of psychosocial development, each marked by a central conflict that individuals must resolve to progress successfully through life.",
    },
  },
  {
    suggestion: "Explore the role of emotions in human behavior",
    sampleMCQ: {
      question:
        "Which theory of emotion suggests that physiological arousal precedes emotional experience?",
      options: [
        "James-Lange theory",
        "Cannon-Bard theory",
        "Schachter-Singer theory",
        "Drive-reduction theory",
      ],
      correctAnswer: "James-Lange theory",
      explanation:
        "The James-Lange theory of emotion posits that physiological arousal, such as increased heart rate, occurs before the emotional experience, and the emotion is a result of interpreting this arousal.",
    },
  },
  {
    suggestion: "Understand the principles of humanistic psychology",
    sampleMCQ: {
      question:
        "Which psychologist is known for the theory of self-actualization?",
      options: ["Carl Rogers", "Abraham Maslow", "B.F. Skinner", "John Watson"],
      correctAnswer: "Abraham Maslow",
      explanation:
        "Abraham Maslow is best known for his theory of self-actualization, which suggests that individuals strive to reach their fullest potential once their basic needs are met.",
    },
  },
  {
    suggestion: "Learn about the biological basis of behavior",
    sampleMCQ: {
      question:
        "Which part of the brain is primarily responsible for regulating emotions?",
      options: ["Cerebellum", "Hippocampus", "Amygdala", "Prefrontal cortex"],
      correctAnswer: "Amygdala",
      explanation:
        "The amygdala, located in the limbic system, plays a crucial role in regulating emotions, particularly fear and aggression.",
    },
  },
  {
    suggestion: "Explore the impact of stress on psychological health",
    sampleMCQ: {
      question: "Which of the following is a common effect of chronic stress?",
      options: [
        "Improved memory",
        "Increased happiness",
        "Higher blood pressure",
        "Lower anxiety",
      ],
      correctAnswer: "Higher blood pressure",
      explanation:
        "Chronic stress can lead to various negative health effects, including higher blood pressure, which increases the risk of heart disease and other health issues.",
    },
  },
];
