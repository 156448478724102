// Law - list of subtopics and related MCQs
export const lawTopics = [
  {
    suggestion: "Understand the basics of constitutional law",
    sampleMCQ: {
      question:
        "Which of the following is the supreme law of the land in the United States?",
      options: [
        "The Declaration of Independence",
        "The U.S. Constitution",
        "The Bill of Rights",
        "The Federalist Papers",
      ],
      correctAnswer: "The U.S. Constitution",
      explanation:
        "The U.S. Constitution is the supreme law of the land in the United States, establishing the framework for the government and outlining the rights of citizens.",
    },
  },
  {
    suggestion: "Learn about criminal law and its principles",
    sampleMCQ: {
      question: "Which of the following is a key component of criminal law?",
      options: [
        "Contracts",
        "Civil wrongs",
        "Punishment for offenses",
        "Intellectual property",
      ],
      correctAnswer: "Punishment for offenses",
      explanation:
        "Criminal law deals with actions that are offenses against the public, society, or state, and typically involves punishment such as fines, imprisonment, or both.",
    },
  },
  {
    suggestion: "Explore the fundamentals of contract law",
    sampleMCQ: {
      question:
        "Which of the following is required for a contract to be legally enforceable?",
      options: [
        "Written agreement",
        "Mutual consent",
        "Government approval",
        "Third-party witness",
      ],
      correctAnswer: "Mutual consent",
      explanation:
        "For a contract to be legally enforceable, there must be mutual consent (offer and acceptance), consideration, and the intent to create legal obligations.",
    },
  },
  {
    suggestion: "Learn about tort law and civil wrongs",
    sampleMCQ: {
      question: "Which of the following is an example of a tort?",
      options: [
        "Breach of contract",
        "Tax evasion",
        "Negligence",
        "Bankruptcy",
      ],
      correctAnswer: "Negligence",
      explanation:
        "Negligence is a type of tort, which refers to a civil wrong that causes harm or injury to another person, leading to legal liability for damages.",
    },
  },
  {
    suggestion: "Understand the structure of the judicial system",
    sampleMCQ: {
      question: "Which is the highest court in the United States?",
      options: [
        "The Court of Appeals",
        "The Supreme Court",
        "The Federal District Court",
        "The State Supreme Court",
      ],
      correctAnswer: "The Supreme Court",
      explanation:
        "The Supreme Court is the highest court in the United States, and its rulings are final. It has the authority to interpret the Constitution and review decisions made by lower courts.",
    },
  },
  {
    suggestion: "Explore the basics of intellectual property law",
    sampleMCQ: {
      question:
        "Which of the following protects an inventor’s rights to their invention?",
      options: ["Trademark", "Copyright", "Patent", "Trade secret"],
      correctAnswer: "Patent",
      explanation:
        "A patent is a legal protection that grants the inventor exclusive rights to their invention for a specified period, preventing others from making, using, or selling the invention.",
    },
  },
  {
    suggestion: "Learn about employment and labor law",
    sampleMCQ: {
      question:
        "Which of the following laws governs the relationship between employers and employees?",
      options: ["Tort law", "Family law", "Labor law", "Property law"],
      correctAnswer: "Labor law",
      explanation:
        "Labor law governs the relationship between employers and employees, covering topics like wages, working conditions, workers' rights, and labor unions.",
    },
  },
  {
    suggestion: "Understand family law and its role in society",
    sampleMCQ: {
      question: "Which of the following is typically covered under family law?",
      options: [
        "Corporate mergers",
        "Divorce and child custody",
        "Patent disputes",
        "Environmental regulations",
      ],
      correctAnswer: "Divorce and child custody",
      explanation:
        "Family law deals with legal matters related to family relationships, including marriage, divorce, child custody, adoption, and domestic partnerships.",
    },
  },
  {
    suggestion: "Explore the basics of international law",
    sampleMCQ: {
      question: "Which body of law governs the relationships between nations?",
      options: [
        "Constitutional law",
        "Criminal law",
        "International law",
        "Administrative law",
      ],
      correctAnswer: "International law",
      explanation:
        "International law governs the relationships and interactions between nations, including treaties, international agreements, human rights, and global trade.",
    },
  },
  {
    suggestion: "Learn about administrative law and regulatory agencies",
    sampleMCQ: {
      question:
        "Which of the following is an example of an administrative agency in the United States?",
      options: [
        "The U.S. Supreme Court",
        "The Department of Justice",
        "The Environmental Protection Agency (EPA)",
        "The Federal Reserve",
      ],
      correctAnswer: "The Environmental Protection Agency (EPA)",
      explanation:
        "The Environmental Protection Agency (EPA) is an administrative agency responsible for enforcing environmental regulations and protecting natural resources in the United States.",
    },
  },
  {
    suggestion: "Understand corporate law and business regulations",
    sampleMCQ: {
      question:
        "Which document sets out the rules governing the internal management of a corporation?",
      options: [
        "The Articles of Incorporation",
        "The Bill of Rights",
        "The Shareholder Agreement",
        "The Operating Agreement",
      ],
      correctAnswer: "The Articles of Incorporation",
      explanation:
        "The Articles of Incorporation is a document that establishes the existence of a corporation and sets out the rules and regulations for its governance and management.",
    },
  },
  {
    suggestion: "Learn about environmental law and its impact",
    sampleMCQ: {
      question: "Which of the following is an example of environmental law?",
      options: [
        "The Clean Air Act",
        "The Affordable Care Act",
        "The Civil Rights Act",
        "The Securities Act",
      ],
      correctAnswer: "The Clean Air Act",
      explanation:
        "The Clean Air Act is an environmental law in the United States that regulates air emissions from stationary and mobile sources to protect public health and the environment.",
    },
  },
  {
    suggestion: "Explore the concept of human rights in law",
    sampleMCQ: {
      question:
        "Which international document outlines fundamental human rights?",
      options: [
        "The Magna Carta",
        "The Universal Declaration of Human Rights",
        "The Treaty of Versailles",
        "The United States Constitution",
      ],
      correctAnswer: "The Universal Declaration of Human Rights",
      explanation:
        "The Universal Declaration of Human Rights (UDHR) is an international document adopted by the United Nations that outlines fundamental rights and freedoms for all people.",
    },
  },
  {
    suggestion: "Learn about property law and ownership rights",
    sampleMCQ: {
      question:
        "Which type of property refers to land and anything permanently attached to it?",
      options: [
        "Intellectual property",
        "Personal property",
        "Real property",
        "Tangible property",
      ],
      correctAnswer: "Real property",
      explanation:
        "Real property refers to land and anything permanently attached to it, such as buildings, homes, or natural resources, as opposed to personal property, which refers to movable items.",
    },
  },
  {
    suggestion: "Understand the principles of civil rights law",
    sampleMCQ: {
      question:
        "Which of the following laws was enacted to prohibit racial discrimination in the United States?",
      options: [
        "The Voting Rights Act",
        "The Civil Rights Act of 1964",
        "The Patriot Act",
        "The Equal Pay Act",
      ],
      correctAnswer: "The Civil Rights Act of 1964",
      explanation:
        "The Civil Rights Act of 1964 is a landmark law in the United States that prohibits racial discrimination in employment, education, and public accommodations.",
    },
  },
];
