// Arts & Design - list of subtopics and related MCQs
export const artsDesignTopics = [
  {
    suggestion: "Understand the elements of visual art",
    sampleMCQ: {
      question: "Which of the following is an element of visual art?",
      options: ["Texture", "Symmetry", "Balance", "Contrast"],
      correctAnswer: "Texture",
      explanation:
        "Texture refers to the surface quality or feel of an object, such as smoothness, roughness, or softness, and is a key element of visual art.",
    },
  },
  {
    suggestion: "Learn about different art movements",
    sampleMCQ: {
      question:
        "Which art movement is known for its emphasis on light and color, often capturing scenes of everyday life?",
      options: ["Impressionism", "Cubism", "Baroque", "Dada"],
      correctAnswer: "Impressionism",
      explanation:
        "Impressionism is known for its focus on light and color, with artists like Claude Monet capturing scenes of everyday life in a style that emphasizes perception and mood.",
    },
  },
  {
    suggestion: "Explore the basics of color theory",
    sampleMCQ: {
      question: "What are the primary colors in traditional color theory?",
      options: [
        "Red, Green, Blue",
        "Red, Yellow, Blue",
        "Yellow, Green, Purple",
        "Blue, Black, White",
      ],
      correctAnswer: "Red, Yellow, Blue",
      explanation:
        "In traditional color theory, the primary colors are red, yellow, and blue. These colors cannot be created by mixing other colors but can be combined to create secondary and tertiary colors.",
    },
  },
  {
    suggestion: "Learn about the principles of design",
    sampleMCQ: {
      question:
        "Which principle of design refers to the distribution of visual weight in a composition?",
      options: ["Harmony", "Proportion", "Balance", "Emphasis"],
      correctAnswer: "Balance",
      explanation:
        "Balance is the principle of design that refers to how the visual weight of elements is distributed in a composition, ensuring that no one part of the work overpowers the rest.",
    },
  },
  {
    suggestion: "Explore the role of typography in graphic design",
    sampleMCQ: {
      question:
        "Which of the following refers to the style and appearance of printed text?",
      options: ["Typography", "Kerning", "Hierarchy", "Grid"],
      correctAnswer: "Typography",
      explanation:
        "Typography refers to the art and technique of arranging type to make written language legible, readable, and visually appealing when displayed.",
    },
  },
  {
    suggestion: "Understand the history and impact of the Renaissance in art",
    sampleMCQ: {
      question:
        "Which artist is known for painting the ceiling of the Sistine Chapel?",
      options: ["Leonardo da Vinci", "Raphael", "Michelangelo", "Titian"],
      correctAnswer: "Michelangelo",
      explanation:
        "Michelangelo is famous for painting the ceiling of the Sistine Chapel, a masterpiece of Renaissance art that depicts scenes from the Book of Genesis.",
    },
  },
  {
    suggestion: "Learn about the use of perspective in art",
    sampleMCQ: {
      question:
        "What technique in art creates the illusion of depth on a flat surface?",
      options: ["Chiaroscuro", "Sfumato", "Perspective", "Fresco"],
      correctAnswer: "Perspective",
      explanation:
        "Perspective is a technique used in art to create the illusion of depth on a flat surface by making distant objects appear smaller and closer objects appear larger.",
    },
  },
  {
    suggestion: "Explore the role of fashion design in culture",
    sampleMCQ: {
      question:
        "Which of the following is considered a major fashion capital of the world?",
      options: ["Berlin", "Paris", "Tokyo", "Milan"],
      correctAnswer: "Paris",
      explanation:
        "Paris is one of the major fashion capitals of the world, known for its rich history in haute couture and its influence on global fashion trends.",
    },
  },
  {
    suggestion: "Understand the basics of interior design",
    sampleMCQ: {
      question:
        "Which principle of interior design focuses on creating a sense of unity among different elements?",
      options: ["Proportion", "Emphasis", "Rhythm", "Harmony"],
      correctAnswer: "Harmony",
      explanation:
        "Harmony in interior design refers to the pleasing arrangement and compatibility of different design elements to create a sense of unity and balance.",
    },
  },
  {
    suggestion: "Learn about the development of modern architecture",
    sampleMCQ: {
      question:
        "Which architect is known for designing the Guggenheim Museum in New York?",
      options: [
        "Frank Gehry",
        "Frank Lloyd Wright",
        "Le Corbusier",
        "Zaha Hadid",
      ],
      correctAnswer: "Frank Lloyd Wright",
      explanation:
        "Frank Lloyd Wright is known for designing the iconic Guggenheim Museum in New York, a masterpiece of modern architecture that features a spiral ramp and open floor plan.",
    },
  },
  {
    suggestion: "Explore the basics of sculpture",
    sampleMCQ: {
      question:
        "Which term describes a sculpture that is carved or built into a flat surface?",
      options: ["Freestanding", "Kinetic", "Relief", "Installation"],
      correctAnswer: "Relief",
      explanation:
        "Relief sculpture is a type of sculpture where the forms are raised or carved into a flat surface, creating an image that projects from the background.",
    },
  },
  {
    suggestion: "Learn about photography as an art form",
    sampleMCQ: {
      question:
        "Which type of photography focuses on capturing subjects in natural surroundings?",
      options: [
        "Portrait photography",
        "Fashion photography",
        "Street photography",
        "Landscape photography",
      ],
      correctAnswer: "Landscape photography",
      explanation:
        "Landscape photography focuses on capturing outdoor scenes, often emphasizing natural surroundings such as mountains, forests, and bodies of water.",
    },
  },
  {
    suggestion: "Understand the role of digital media in contemporary design",
    sampleMCQ: {
      question:
        "Which software is commonly used for creating vector-based graphics in digital design?",
      options: [
        "Adobe Photoshop",
        "Adobe Illustrator",
        "Corel Painter",
        "Blender",
      ],
      correctAnswer: "Adobe Illustrator",
      explanation:
        "Adobe Illustrator is widely used for creating vector-based graphics, making it a key tool for digital artists and graphic designers.",
    },
  },
  {
    suggestion:
      "Explore the impact of street art and graffiti on contemporary culture",
    sampleMCQ: {
      question:
        "Which of the following street artists is known for creating politically charged stencils?",
      options: [
        "Banksy",
        "Jean-Michel Basquiat",
        "Shepard Fairey",
        "Keith Haring",
      ],
      correctAnswer: "Banksy",
      explanation:
        "Banksy is a famous street artist known for using stencils to create politically and socially charged works of art that challenge authority and societal norms.",
    },
  },
];
