// Communication - list of subtopics and related MCQs
export const communicationTopics = [
  {
    suggestion: "Understand the basics of interpersonal communication",
    sampleMCQ: {
      question:
        "Which of the following is essential for effective interpersonal communication?",
      options: [
        "Avoiding eye contact",
        "Active listening",
        "Interrupting the speaker",
        "Speaking louder",
      ],
      correctAnswer: "Active listening",
      explanation:
        "Active listening is crucial for effective interpersonal communication, as it involves fully focusing on, understanding, and responding to the speaker, which helps build strong connections and understanding.",
    },
  },
  {
    suggestion: "Learn about non-verbal communication",
    sampleMCQ: {
      question:
        "Which of the following is an example of non-verbal communication?",
      options: [
        "Writing a letter",
        "Speaking in a meeting",
        "Nodding in agreement",
        "Sending a text message",
      ],
      correctAnswer: "Nodding in agreement",
      explanation:
        "Non-verbal communication includes body language, gestures, facial expressions, and other forms of conveying meaning without using words. Nodding in agreement is a common non-verbal cue.",
    },
  },
  {
    suggestion: "Explore the concept of mass communication",
    sampleMCQ: {
      question: "Which medium is considered a form of mass communication?",
      options: [
        "Face-to-face conversation",
        "Telephone call",
        "Television broadcast",
        "Personal letter",
      ],
      correctAnswer: "Television broadcast",
      explanation:
        "Mass communication refers to the dissemination of information to large audiences through mediums such as television, radio, newspapers, and the internet.",
    },
  },
  {
    suggestion: "Understand the importance of feedback in communication",
    sampleMCQ: {
      question: "What role does feedback play in the communication process?",
      options: [
        "It interrupts the flow of communication",
        "It helps clarify messages and ensure understanding",
        "It is unnecessary in effective communication",
        "It distracts the listener",
      ],
      correctAnswer: "It helps clarify messages and ensure understanding",
      explanation:
        "Feedback is a critical part of the communication process, as it allows the sender to know whether the message was understood correctly and enables adjustments if needed.",
    },
  },
  {
    suggestion: "Learn about the barriers to effective communication",
    sampleMCQ: {
      question:
        "Which of the following is an example of a communication barrier?",
      options: [
        "Clear pronunciation",
        "Cultural differences",
        "Active listening",
        "Eye contact",
      ],
      correctAnswer: "Cultural differences",
      explanation:
        "Cultural differences can act as a barrier to effective communication, as different cultural backgrounds can lead to misunderstandings or misinterpretations of messages.",
    },
  },
  {
    suggestion: "Explore the role of digital communication in modern society",
    sampleMCQ: {
      question:
        "Which of the following is an example of digital communication?",
      options: [
        "A face-to-face meeting",
        "Writing a handwritten letter",
        "A video conference call",
        "Passing a note in class",
      ],
      correctAnswer: "A video conference call",
      explanation:
        "Digital communication involves the use of technology to share information, such as through emails, video conference calls, social media, and messaging apps.",
    },
  },
  {
    suggestion: "Understand the concept of cross-cultural communication",
    sampleMCQ: {
      question:
        "Which of the following can improve cross-cultural communication?",
      options: [
        "Assuming everyone shares the same values",
        "Learning about other cultures and customs",
        "Speaking more loudly",
        "Ignoring cultural differences",
      ],
      correctAnswer: "Learning about other cultures and customs",
      explanation:
        "Learning about other cultures and customs helps improve cross-cultural communication by fostering understanding, reducing miscommunication, and building respect for diverse perspectives.",
    },
  },
  {
    suggestion:
      "Learn about the process of encoding and decoding in communication",
    sampleMCQ: {
      question: "What does encoding refer to in the communication process?",
      options: [
        "Interpreting the message",
        "Creating and sending the message",
        "Providing feedback",
        "Ignoring the message",
      ],
      correctAnswer: "Creating and sending the message",
      explanation:
        "Encoding refers to the process of creating and sending a message, where the sender translates thoughts or ideas into a form that can be communicated, such as words, gestures, or symbols.",
    },
  },
  {
    suggestion: "Explore the role of communication in conflict resolution",
    sampleMCQ: {
      question:
        "Which communication strategy is most effective in resolving conflicts?",
      options: [
        "Ignoring the issue",
        "Being aggressive",
        "Engaging in active listening and open dialogue",
        "Making assumptions",
      ],
      correctAnswer: "Engaging in active listening and open dialogue",
      explanation:
        "Active listening and open dialogue are essential communication strategies for resolving conflicts, as they encourage understanding, empathy, and collaborative problem-solving.",
    },
  },
  {
    suggestion: "Understand the principles of persuasive communication",
    sampleMCQ: {
      question:
        "Which of the following is a key element of persuasive communication?",
      options: [
        "Using logical arguments and evidence",
        "Yelling to make a point",
        "Ignoring the audience’s concerns",
        "Avoiding eye contact",
      ],
      correctAnswer: "Using logical arguments and evidence",
      explanation:
        "Persuasive communication involves using logical arguments, evidence, and emotional appeals to influence the attitudes, beliefs, or behaviors of others.",
    },
  },
  {
    suggestion: "Learn about the different types of communication styles",
    sampleMCQ: {
      question:
        "Which communication style involves expressing thoughts and feelings in a clear, direct, and respectful way?",
      options: ["Passive", "Aggressive", "Assertive", "Submissive"],
      correctAnswer: "Assertive",
      explanation:
        "An assertive communication style involves expressing thoughts, feelings, and needs clearly and directly while respecting the rights and opinions of others, promoting effective communication.",
    },
  },
  {
    suggestion: "Explore the impact of social media on communication",
    sampleMCQ: {
      question:
        "Which of the following is a common effect of social media on communication?",
      options: [
        "Decreased global connectivity",
        "Slower communication speed",
        "Increased access to information and global connectivity",
        "Reduced participation in public discourse",
      ],
      correctAnswer: "Increased access to information and global connectivity",
      explanation:
        "Social media has increased global connectivity by enabling real-time communication and access to information from diverse sources, fostering greater interconnectedness across the world.",
    },
  },
  {
    suggestion:
      "Understand the importance of verbal and non-verbal communication",
    sampleMCQ: {
      question: "Which of the following is an example of verbal communication?",
      options: [
        "A facial expression",
        "A handshake",
        "A spoken conversation",
        "A gesture",
      ],
      correctAnswer: "A spoken conversation",
      explanation:
        "Verbal communication refers to the use of spoken or written words to convey a message, while non-verbal communication includes body language, gestures, and facial expressions.",
    },
  },
  {
    suggestion: "Learn about group communication dynamics",
    sampleMCQ: {
      question:
        "Which of the following is a characteristic of effective group communication?",
      options: [
        "One person dominates the discussion",
        "Equal participation from all members",
        "Frequent interruptions",
        "Ignoring differing opinions",
      ],
      correctAnswer: "Equal participation from all members",
      explanation:
        "Effective group communication involves equal participation from all members, allowing for diverse perspectives and collaborative decision-making, which enhances group productivity and cohesion.",
    },
  },
];
