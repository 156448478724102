// Philosophy & Ethics - list of subtopics and related MCQs
export const philosophyTopics = [
  {
    suggestion: "Understand the basics of epistemology",
    sampleMCQ: {
      question:
        "Which branch of philosophy deals with the theory of knowledge?",
      options: ["Metaphysics", "Ethics", "Epistemology", "Aesthetics"],
      correctAnswer: "Epistemology",
      explanation:
        "Epistemology is the branch of philosophy concerned with the nature, scope, and limits of human knowledge, including how we acquire and validate what we know.",
    },
  },
  {
    suggestion: "Learn about metaphysical concepts",
    sampleMCQ: {
      question: "Which question is central to metaphysics?",
      options: [
        "What is the nature of reality?",
        "What is the right action?",
        "How do we know what we know?",
        "What is beauty?",
      ],
      correctAnswer: "What is the nature of reality?",
      explanation:
        "Metaphysics is the branch of philosophy that explores the fundamental nature of reality, including concepts such as existence, being, time, and space.",
    },
  },
  {
    suggestion: "Explore ethical theories and moral philosophy",
    sampleMCQ: {
      question: "Which philosopher is known for developing utilitarianism?",
      options: [
        "Immanuel Kant",
        "John Stuart Mill",
        "Aristotle",
        "René Descartes",
      ],
      correctAnswer: "John Stuart Mill",
      explanation:
        "John Stuart Mill is one of the key figures in utilitarianism, a theory in ethics which holds that the best action is the one that maximizes overall happiness or well-being.",
    },
  },
  {
    suggestion: "Understand existentialism and its key thinkers",
    sampleMCQ: {
      question:
        "Which existentialist philosopher famously said, 'Existence precedes essence'?",
      options: [
        "Friedrich Nietzsche",
        "Søren Kierkegaard",
        "Jean-Paul Sartre",
        "Martin Heidegger",
      ],
      correctAnswer: "Jean-Paul Sartre",
      explanation:
        "Jean-Paul Sartre, a leading figure in existentialism, argued that individuals create their own meaning in life, as there is no predefined essence or purpose before existence.",
    },
  },
  {
    suggestion: "Learn about the philosophy of mind",
    sampleMCQ: {
      question:
        "Which theory holds that the mind and body are distinct and separate substances?",
      options: ["Materialism", "Dualism", "Behaviorism", "Functionalism"],
      correctAnswer: "Dualism",
      explanation:
        "Dualism, most famously associated with René Descartes, is the theory that the mind and body are distinct substances, with the mind being non-physical and the body being physical.",
    },
  },
  {
    suggestion: "Explore political philosophy and justice",
    sampleMCQ: {
      question:
        "Which philosopher wrote 'The Republic' and introduced the concept of the 'Philosopher King'?",
      options: ["Aristotle", "Thomas Hobbes", "John Locke", "Plato"],
      correctAnswer: "Plato",
      explanation:
        "Plato's 'The Republic' presents the concept of the 'Philosopher King,' an ideal ruler who governs not for personal gain but for the good of all, based on wisdom and reason.",
    },
  },
  {
    suggestion: "Understand the basics of logic and reasoning",
    sampleMCQ: {
      question: "Which of the following is an example of deductive reasoning?",
      options: [
        "All men are mortal. Socrates is a man. Therefore, Socrates is mortal.",
        "I saw the sun rise today, so it will rise tomorrow.",
        "Everyone who exercises is healthy. John is healthy. Therefore, John exercises.",
        "Most people like chocolate, so Jane probably likes chocolate.",
      ],
      correctAnswer:
        "All men are mortal. Socrates is a man. Therefore, Socrates is mortal.",
      explanation:
        "Deductive reasoning moves from a general premise to a specific conclusion, as in the classic syllogism about Socrates and mortality.",
    },
  },
  {
    suggestion: "Learn about the philosophy of language",
    sampleMCQ: {
      question:
        "Which philosopher is known for the statement, 'The limits of my language mean the limits of my world'?",
      options: [
        "Ferdinand de Saussure",
        "Ludwig Wittgenstein",
        "Noam Chomsky",
        "Michel Foucault",
      ],
      correctAnswer: "Ludwig Wittgenstein",
      explanation:
        "Ludwig Wittgenstein, in his early work, suggested that language shapes our understanding of the world, and that what we cannot express in language, we cannot truly know.",
    },
  },
  {
    suggestion: "Explore the relationship between philosophy and science",
    sampleMCQ: {
      question:
        "Which philosopher is known for his contributions to the scientific method?",
      options: [
        "Aristotle",
        "Galileo Galilei",
        "Francis Bacon",
        "Thomas Aquinas",
      ],
      correctAnswer: "Francis Bacon",
      explanation:
        "Francis Bacon is known for developing the scientific method, advocating for empirical research and experimentation as the basis for acquiring knowledge.",
    },
  },
  {
    suggestion: "Understand deontological ethics and duty-based morality",
    sampleMCQ: {
      question:
        "Which philosopher is most associated with deontological ethics?",
      options: ["Jeremy Bentham", "Immanuel Kant", "David Hume", "Epicurus"],
      correctAnswer: "Immanuel Kant",
      explanation:
        "Immanuel Kant is the key figure associated with deontological ethics, which focuses on the inherent morality of actions themselves, rather than their consequences.",
    },
  },
  {
    suggestion: "Learn about aesthetics and the philosophy of art",
    sampleMCQ: {
      question:
        "Which branch of philosophy is concerned with the nature of beauty and artistic expression?",
      options: ["Epistemology", "Aesthetics", "Metaphysics", "Ethics"],
      correctAnswer: "Aesthetics",
      explanation:
        "Aesthetics is the branch of philosophy that deals with questions about beauty, art, taste, and artistic expression.",
    },
  },
  {
    suggestion: "Explore moral relativism and ethical subjectivism",
    sampleMCQ: {
      question: "What does moral relativism suggest about ethical truths?",
      options: [
        "Ethical truths are objective and universal.",
        "Ethical truths depend on cultural or individual perspectives.",
        "Ethical truths are determined by divine authority.",
        "Ethical truths are based solely on natural law.",
      ],
      correctAnswer:
        "Ethical truths depend on cultural or individual perspectives.",
      explanation:
        "Moral relativism suggests that ethical truths are not absolute but are shaped by cultural or individual perspectives, meaning that what is considered 'right' or 'wrong' can vary.",
    },
  },
  {
    suggestion: "Understand utilitarian ethics and its focus on consequences",
    sampleMCQ: {
      question:
        "Which of the following principles is central to utilitarianism?",
      options: [
        "The categorical imperative",
        "The greatest happiness for the greatest number",
        "The inherent value of individuals",
        "The social contract",
      ],
      correctAnswer: "The greatest happiness for the greatest number",
      explanation:
        "Utilitarianism is an ethical theory that holds that the best action is the one that maximizes happiness or well-being for the greatest number of people.",
    },
  },
  {
    suggestion:
      "Learn about the philosophy of religion and arguments for the existence of God",
    sampleMCQ: {
      question:
        "Which philosopher is known for the ontological argument for the existence of God?",
      options: [
        "Thomas Aquinas",
        "Anselm of Canterbury",
        "David Hume",
        "Baruch Spinoza",
      ],
      correctAnswer: "Anselm of Canterbury",
      explanation:
        "Anselm of Canterbury developed the ontological argument, which posits that the concept of God as the greatest being implies His existence, as existence is a necessary attribute of the greatest conceivable being.",
    },
  },
];
