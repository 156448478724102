// Social Sciences - list of subtopics and related MCQs
export const socialScienceTopics = [
  {
    suggestion: "Understand the basic principles of sociology",
    sampleMCQ: {
      question:
        "Which of the following concepts refers to the way society is structured into different groups?",
      options: [
        "Social mobility",
        "Social stratification",
        "Socialization",
        "Cultural diffusion",
      ],
      correctAnswer: "Social stratification",
      explanation:
        "Social stratification refers to the way society is divided into hierarchies or strata based on factors like class, race, and economic status.",
    },
  },
  {
    suggestion: "Learn about different psychological theories",
    sampleMCQ: {
      question:
        "Which of the following is a key figure in psychoanalytic theory?",
      options: ["Jean Piaget", "Sigmund Freud", "B.F. Skinner", "Carl Rogers"],
      correctAnswer: "Sigmund Freud",
      explanation:
        "Sigmund Freud is the founder of psychoanalytic theory, which emphasizes unconscious motivations and early childhood experiences in shaping behavior.",
    },
  },
  {
    suggestion: "Explore the fundamentals of anthropology",
    sampleMCQ: {
      question: "What does cultural anthropology primarily study?",
      options: [
        "Human evolution",
        "Societal laws",
        "Human culture and behavior",
        "Political structures",
      ],
      correctAnswer: "Human culture and behavior",
      explanation:
        "Cultural anthropology studies human culture, including social practices, rituals, language, and traditions across different societies.",
    },
  },
  {
    suggestion: "Learn about economic systems and their differences",
    sampleMCQ: {
      question:
        "Which economic system is based on supply and demand with little government control?",
      options: [
        "Command economy",
        "Mixed economy",
        "Market economy",
        "Traditional economy",
      ],
      correctAnswer: "Market economy",
      explanation:
        "A market economy operates based on supply and demand with minimal government intervention, whereas command economies are controlled by the government.",
    },
  },
  {
    suggestion: "Understand the principles of political science",
    sampleMCQ: {
      question:
        "Which of the following is an essential characteristic of democracy?",
      options: [
        "Rule by a single party",
        "Freedom of speech",
        "Totalitarian rule",
        "Censorship of media",
      ],
      correctAnswer: "Freedom of speech",
      explanation:
        "Freedom of speech is a core value in democratic systems, where citizens have the right to express their opinions without fear of government retaliation.",
    },
  },
  {
    suggestion: "Explore criminology and theories of crime",
    sampleMCQ: {
      question: "Which theory suggests that crime is a learned behavior?",
      options: [
        "Strain theory",
        "Social learning theory",
        "Labeling theory",
        "Routine activities theory",
      ],
      correctAnswer: "Social learning theory",
      explanation:
        "Social learning theory, developed by Albert Bandura, suggests that crime is learned through interactions with others, especially in group settings.",
    },
  },
  {
    suggestion: "Understand human geography and its impact on societies",
    sampleMCQ: {
      question:
        "What is the study of how human activity affects or is influenced by the Earth’s surface?",
      options: [
        "Urban geography",
        "Physical geography",
        "Cultural geography",
        "Political geography",
      ],
      correctAnswer: "Cultural geography",
      explanation:
        "Cultural geography studies how human activities, such as culture, religion, and language, interact with the Earth's surface and physical environment.",
    },
  },
  {
    suggestion: "Learn about the different branches of psychology",
    sampleMCQ: {
      question:
        "Which branch of psychology focuses on diagnosing and treating mental disorders?",
      options: [
        "Cognitive psychology",
        "Developmental psychology",
        "Clinical psychology",
        "Social psychology",
      ],
      correctAnswer: "Clinical psychology",
      explanation:
        "Clinical psychology is concerned with the diagnosis and treatment of mental health disorders, while other branches focus on different aspects of human behavior.",
    },
  },
  {
    suggestion: "Understand the relationship between culture and society",
    sampleMCQ: {
      question:
        "Which term describes the process by which a person learns and internalizes the norms of their society?",
      options: [
        "Socialization",
        "Acculturation",
        "Assimilation",
        "Enculturation",
      ],
      correctAnswer: "Socialization",
      explanation:
        "Socialization is the lifelong process where individuals learn and internalize the values, norms, and practices of their society, shaping their behavior and identity.",
    },
  },
  {
    suggestion:
      "Learn about key concepts in economics, such as supply and demand",
    sampleMCQ: {
      question: "What happens when demand for a product exceeds supply?",
      options: [
        "The price decreases",
        "The product becomes scarce",
        "The price remains the same",
        "The government intervenes",
      ],
      correctAnswer: "The product becomes scarce",
      explanation:
        "When demand for a product exceeds supply, it becomes scarce, often leading to higher prices as consumers compete for limited goods.",
    },
  },
  {
    suggestion:
      "Explore the role of government and public policy in political science",
    sampleMCQ: {
      question: "Which of the following is an example of public policy?",
      options: [
        "Individual opinions",
        "Corporate strategies",
        "Health care regulations",
        "Social media trends",
      ],
      correctAnswer: "Health care regulations",
      explanation:
        "Public policy refers to government actions like health care regulations, laws, and guidelines aimed at solving public issues and benefiting society as a whole.",
    },
  },
  {
    suggestion: "Understand the basics of international relations",
    sampleMCQ: {
      question:
        "Which organization is primarily responsible for maintaining international peace and security?",
      options: [
        "The World Bank",
        "The United Nations",
        "NATO",
        "The European Union",
      ],
      correctAnswer: "The United Nations",
      explanation:
        "The United Nations (UN) is an international organization tasked with maintaining peace and security, promoting human rights, and fostering international cooperation.",
    },
  },
  {
    suggestion: "Learn about the evolution of human societies in anthropology",
    sampleMCQ: {
      question: "Which of the following terms refers to early human ancestors?",
      options: ["Hominins", "Neanderthals", "Homo sapiens", "Homo erectus"],
      correctAnswer: "Hominins",
      explanation:
        "Hominins refer to early human ancestors, including species like Homo erectus and Neanderthals, which evolved before modern Homo sapiens.",
    },
  },
  {
    suggestion: "Explore the concept of deviance in sociology",
    sampleMCQ: {
      question:
        "Which of the following describes behavior that violates societal norms?",
      options: [
        "Conformity",
        "Deviance",
        "Social cohesion",
        "Institutionalization",
      ],
      correctAnswer: "Deviance",
      explanation:
        "Deviance refers to actions or behaviors that violate societal norms, and can range from minor infractions to serious criminal acts.",
    },
  },
];
