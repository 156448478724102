// Languages & Linguistics - list of subtopics and related MCQs
export const languageTopics = [
  {
    suggestion: "Understand the fundamentals of grammar",
    sampleMCQ: {
      question: "Which of the following is an example of a preposition?",
      options: ["Quickly", "And", "In", "Walk"],
      correctAnswer: "In",
      explanation:
        "A preposition is a word that shows the relationship between a noun or pronoun and other words in a sentence. 'In' is an example of a preposition, indicating a relationship between objects.",
    },
  },
  {
    suggestion: "Learn about the different parts of speech",
    sampleMCQ: {
      question: "Which part of speech describes an action or state of being?",
      options: ["Noun", "Adjective", "Verb", "Adverb"],
      correctAnswer: "Verb",
      explanation:
        "A verb is a word that describes an action, event, or state of being. For example, 'run' and 'is' are verbs.",
    },
  },
  {
    suggestion: "Explore the basics of sentence structure",
    sampleMCQ: {
      question: "What is the term for the main idea or subject in a sentence?",
      options: ["Predicate", "Object", "Clause", "Subject"],
      correctAnswer: "Subject",
      explanation:
        "The subject of a sentence is the person, place, thing, or idea that is performing the action of the verb.",
    },
  },
  {
    suggestion: "Learn about different language families",
    sampleMCQ: {
      question: "Which language belongs to the Romance language family?",
      options: ["German", "Chinese", "Spanish", "Russian"],
      correctAnswer: "Spanish",
      explanation:
        "Spanish belongs to the Romance language family, which evolved from Latin. Other languages in this family include French, Italian, and Portuguese.",
    },
  },
  {
    suggestion: "Understand the importance of phonetics and phonology",
    sampleMCQ: {
      question: "What is phonology the study of?",
      options: [
        "Word meaning",
        "Sentence structure",
        "Sound patterns in language",
        "Language history",
      ],
      correctAnswer: "Sound patterns in language",
      explanation:
        "Phonology is the study of the sound systems of languages, including how sounds function and are organized within particular languages.",
    },
  },
  {
    suggestion: "Explore the concept of bilingualism and multilingualism",
    sampleMCQ: {
      question:
        "What term describes the ability to speak two languages fluently?",
      options: [
        "Multilingualism",
        "Bilingualism",
        "Monolingualism",
        "Diglossia",
      ],
      correctAnswer: "Bilingualism",
      explanation:
        "Bilingualism refers to the ability to speak two languages fluently, while multilingualism refers to the ability to speak more than two languages.",
    },
  },
  {
    suggestion: "Learn about language acquisition and development",
    sampleMCQ: {
      question:
        "At what stage of life is first language acquisition typically completed?",
      options: [
        "Adulthood",
        "Early childhood",
        "Adolescence",
        "Late childhood",
      ],
      correctAnswer: "Early childhood",
      explanation:
        "First language acquisition typically takes place in early childhood, with most children acquiring basic grammatical structures by the age of five.",
    },
  },
  {
    suggestion: "Understand the difference between dialects and languages",
    sampleMCQ: {
      question: "Which of the following is an example of a dialect?",
      options: ["Mandarin", "English", "British English", "Arabic"],
      correctAnswer: "British English",
      explanation:
        "British English is a dialect of the English language. Dialects are regional or social varieties of a language, distinguished by pronunciation, grammar, or vocabulary.",
    },
  },
  {
    suggestion: "Learn about the role of semantics in language",
    sampleMCQ: {
      question:
        "Semantics is primarily concerned with which aspect of language?",
      options: ["Sound", "Grammar", "Meaning", "Syntax"],
      correctAnswer: "Meaning",
      explanation:
        "Semantics is the branch of linguistics that studies meaning in language, examining how words and sentences convey meaning.",
    },
  },
  {
    suggestion: "Explore the use of pragmatics in communication",
    sampleMCQ: {
      question:
        "Which of the following best describes pragmatics in linguistics?",
      options: [
        "The study of language meaning in context",
        "The rules for sentence formation",
        "The sounds of speech",
        "The history of languages",
      ],
      correctAnswer: "The study of language meaning in context",
      explanation:
        "Pragmatics deals with how context influences the interpretation of meaning in communication, considering factors such as speaker intent and social cues.",
    },
  },
  {
    suggestion: "Understand the concept of linguistic relativity",
    sampleMCQ: {
      question: "What does the linguistic relativity hypothesis propose?",
      options: [
        "Language changes over time",
        "Language affects thought",
        "Language influences culture",
        "Language evolves through technology",
      ],
      correctAnswer: "Language affects thought",
      explanation:
        "The linguistic relativity hypothesis, also known as the Sapir-Whorf hypothesis, suggests that the structure of a language affects how its speakers perceive and think about the world.",
    },
  },
  {
    suggestion: "Learn about historical linguistics and language change",
    sampleMCQ: {
      question:
        "Which term refers to the development of new languages from a common ancestor?",
      options: ["Phonology", "Syntax", "Language divergence", "Semantics"],
      correctAnswer: "Language divergence",
      explanation:
        "Language divergence refers to the process by which new languages develop from a common ancestor, often due to geographical, social, or cultural separation.",
    },
  },
  {
    suggestion: "Explore sociolinguistics and its impact on communication",
    sampleMCQ: {
      question:
        "Which field of study focuses on how language varies and changes in social contexts?",
      options: ["Phonology", "Semantics", "Sociolinguistics", "Syntax"],
      correctAnswer: "Sociolinguistics",
      explanation:
        "Sociolinguistics examines how language use varies across different social groups and contexts, including factors such as class, gender, ethnicity, and region.",
    },
  },
  {
    suggestion:
      "Understand the significance of orthography in written languages",
    sampleMCQ: {
      question: "What does orthography refer to in a language?",
      options: [
        "Word meaning",
        "Spelling system",
        "Sound system",
        "Sentence structure",
      ],
      correctAnswer: "Spelling system",
      explanation:
        "Orthography refers to the conventional spelling system of a language, including the rules and standards used for writing words correctly.",
    },
  },
];
