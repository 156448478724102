import React from "react";
import { Box, Typography } from "@mui/material";
import Lottie from "react-lottie";
import loadingAnimation from "./animations/loading.json"; // Adjust the path if necessary

const LoadingQuestions = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center", // Center the content vertically and horizontally
        gap: 2,
        height: "100vh",
        padding: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Generating Questions...
        </Typography>
        <Lottie options={defaultOptions} height={200} width={200} />
      </Box>
    </Box>
  );
};

export default LoadingQuestions;
