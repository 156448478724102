// Engineering - list of subtopics and related MCQs
export const engineeringTopics = [
  {
    suggestion: "Understand the basic principles of mechanical engineering",
    sampleMCQ: {
      question: "Which of the following is a law of thermodynamics?",
      options: [
        "Law of inertia",
        "Second law of thermodynamics",
        "Law of acceleration",
        "Law of relativity",
      ],
      correctAnswer: "Second law of thermodynamics",
      explanation:
        "The second law of thermodynamics states that entropy in an isolated system always increases over time, a fundamental principle in mechanical engineering.",
    },
  },
  {
    suggestion: "Learn about electrical circuits and their components",
    sampleMCQ: {
      question: "What is the unit of electrical resistance?",
      options: ["Volt", "Ampere", "Ohm", "Watt"],
      correctAnswer: "Ohm",
      explanation:
        "The Ohm is the SI unit of electrical resistance, representing the resistance between two points of a conductor when a constant potential difference of one volt produces a current of one ampere.",
    },
  },
  {
    suggestion: "Explore the role of civil engineering in urban development",
    sampleMCQ: {
      question:
        "Which of the following is a common material used in reinforced concrete?",
      options: ["Iron", "Steel", "Copper", "Aluminum"],
      correctAnswer: "Steel",
      explanation:
        "Steel is commonly used in reinforced concrete to provide tensile strength to complement the concrete's compressive strength.",
    },
  },
  {
    suggestion: "Understand the basics of fluid mechanics",
    sampleMCQ: {
      question:
        "Which of the following principles is used to describe fluid behavior in a pipeline?",
      options: [
        "Bernoulli's principle",
        "Newton's third law",
        "Archimedes' principle",
        "Pascal's law",
      ],
      correctAnswer: "Bernoulli's principle",
      explanation:
        "Bernoulli's principle states that for an incompressible, frictionless fluid, an increase in the speed of the fluid occurs simultaneously with a decrease in pressure.",
    },
  },
  {
    suggestion: "Learn about heat transfer mechanisms",
    sampleMCQ: {
      question: "Which of the following is NOT a mechanism of heat transfer?",
      options: ["Conduction", "Convection", "Radiation", "Reflection"],
      correctAnswer: "Reflection",
      explanation:
        "Reflection is not a mechanism of heat transfer. The three mechanisms of heat transfer are conduction, convection, and radiation.",
    },
  },
  {
    suggestion: "Understand the basics of structural engineering",
    sampleMCQ: {
      question:
        "What type of force acts along the length of a structural member, compressing or stretching it?",
      options: ["Tension", "Shear", "Torsion", "Bending"],
      correctAnswer: "Tension",
      explanation:
        "Tension is a force that acts along the length of a structural member, stretching it, while compression shortens it.",
    },
  },
  {
    suggestion: "Learn about control systems in electrical engineering",
    sampleMCQ: {
      question:
        "What is the primary function of a PID controller in control systems?",
      options: [
        "To regulate the temperature",
        "To control the process flow",
        "To correct error between a desired setpoint and measured value",
        "To maintain constant voltage",
      ],
      correctAnswer:
        "To correct error between a desired setpoint and measured value",
      explanation:
        "A PID controller continuously calculates the error between a desired setpoint and the measured value and applies corrections to minimize the error.",
    },
  },
  {
    suggestion: "Explore the principles of aerodynamics",
    sampleMCQ: {
      question:
        "Which force opposes the motion of an aircraft through the air?",
      options: ["Lift", "Thrust", "Drag", "Weight"],
      correctAnswer: "Drag",
      explanation:
        "Drag is the aerodynamic force that opposes an aircraft's motion through the air, while lift, thrust, and weight are other forces acting on an aircraft.",
    },
  },
  {
    suggestion: "Understand renewable energy systems in modern engineering",
    sampleMCQ: {
      question: "Which of the following is a renewable energy source?",
      options: ["Coal", "Natural gas", "Solar energy", "Nuclear energy"],
      correctAnswer: "Solar energy",
      explanation:
        "Solar energy is considered a renewable energy source because it is naturally replenished and sustainable over long periods, unlike coal or natural gas.",
    },
  },
  {
    suggestion: "Learn about material science and engineering properties",
    sampleMCQ: {
      question:
        "Which property measures a material's ability to deform under tensile stress?",
      options: ["Hardness", "Ductility", "Elasticity", "Brittleness"],
      correctAnswer: "Ductility",
      explanation:
        "Ductility is a material's ability to undergo significant plastic deformation before rupture, typically under tensile stress.",
    },
  },
  {
    suggestion: "Explore the basics of environmental engineering",
    sampleMCQ: {
      question: "What is the primary goal of wastewater treatment?",
      options: [
        "To filter water for industrial use",
        "To recycle water for irrigation",
        "To remove contaminants from water to make it safe for discharge or reuse",
        "To desalinate water for drinking",
      ],
      correctAnswer:
        "To remove contaminants from water to make it safe for discharge or reuse",
      explanation:
        "Wastewater treatment is focused on removing contaminants from water to make it safe for environmental discharge or for reuse.",
    },
  },
  {
    suggestion:
      "Understand the role of computer-aided design (CAD) in engineering",
    sampleMCQ: {
      question:
        "What is the main benefit of using CAD software in engineering design?",
      options: [
        "Manual drafting",
        "Cost estimation",
        "3D modeling and simulation",
        "Quality assurance",
      ],
      correctAnswer: "3D modeling and simulation",
      explanation:
        "CAD software allows engineers to create 3D models and run simulations, which helps improve the efficiency and accuracy of the design process.",
    },
  },
  {
    suggestion: "Learn about mechanical vibrations in engineering systems",
    sampleMCQ: {
      question: "Which of the following is an example of forced vibration?",
      options: [
        "Pendulum in motion",
        "Earthquake vibrations",
        "Vibration of a car engine while running",
        "Wind blowing against a bridge",
      ],
      correctAnswer: "Vibration of a car engine while running",
      explanation:
        "Forced vibration occurs when an external force drives the vibration, such as a car engine running. Pendulum motion and earthquakes are examples of free vibration.",
    },
  },
  {
    suggestion: "Explore the fundamentals of mechatronics",
    sampleMCQ: {
      question:
        "Mechatronics combines which of the following engineering disciplines?",
      options: [
        "Mechanical, electrical, and computer engineering",
        "Civil, chemical, and structural engineering",
        "Aerospace, naval, and automotive engineering",
        "Environmental, nuclear, and petroleum engineering",
      ],
      correctAnswer: "Mechanical, electrical, and computer engineering",
      explanation:
        "Mechatronics is an interdisciplinary field that combines mechanical, electrical, and computer engineering to create complex systems like robotics and automation.",
    },
  },
];
