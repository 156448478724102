import React from "react";
import {
  AppBar as MuiAppBar,
  Toolbar,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import UserMenu from "./UserMenu";
import DeleteIcon from "@mui/icons-material/Delete";

const AppBar = ({
  socket,
  activeQuizSession,
  userInfo,
  toggleDrawer,
  setMode,
  reloadQuizSession,
  avatar,
  isLoggedIn,
  setActiveQuizSession,
  quizCode,
  mode,
  anchorEl,
  themeMode,
  toggleThemeMode,
  setUserInfo,
  setAnchorEl,
  setParticipantId,
  setIsLoggedIn,
}) => {
  return (
    <MuiAppBar position="fixed">
      <Toolbar sx={{ minHeight: "64px" }}>
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          {!activeQuizSession && (
            <>
              {userInfo?.role === "Teacher" && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
              )}

              {userInfo?.role === "Teacher" && (
                <Typography
                  variant="body2"
                  sx={{
                    ml: 3,
                    cursor: "pointer",
                    textDecoration: mode === "choose" ? "underline" : "none",
                    fontWeight: mode === "choose" ? "bold" : "normal",
                  }}
                  onClick={() => {
                    setMode("choose");
                    toggleDrawer(false);
                  }}
                >
                  Create
                </Typography>
              )}

              <Typography
                variant="body2"
                sx={{
                  ml: 3,
                  cursor: "pointer",
                  textDecoration: mode === "join" ? "underline" : "none",
                  fontWeight: mode === "join" ? "bold" : "normal",
                }}
                onClick={() => {
                  setMode("join");
                  toggleDrawer(false);
                }}
              >
                Join
              </Typography>
            </>
          )}

          {activeQuizSession && (
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h9"
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={reloadQuizSession}
              >
                {`${activeQuizSession.title} | Join Code: ${activeQuizSession.quizCode}`}
              </Typography>
              {mode !== "participantActive" && (
                <IconButton
                  color="inherit"
                  onClick={() => {
                    const confirmClose = window.confirm(
                      "Are you sure you want to stop the quiz session?",
                    );
                    if (confirmClose) {
                      socket.emit("endQuizSession", {
                        quizCode: `${activeQuizSession.quizCode}`,
                      });
                      setActiveQuizSession(null);
                    }
                  }}
                  sx={{ ml: 2 }}
                  size="small"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
        {isLoggedIn && (
          <UserMenu
            socket={socket}
            avatar={avatar}
            anchorEl={anchorEl}
            userInfo={userInfo}
            themeMode={themeMode}
            toggleThemeMode={toggleThemeMode}
            setUserInfo={setUserInfo}
            setAnchorEl={setAnchorEl}
            setParticipantId={setParticipantId}
            setIsLoggedIn={setIsLoggedIn}
          />
        )}
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
