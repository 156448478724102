export const scienceTopics = [
  {
    suggestion: "Understand the Scientific Method",
    sampleMCQ: {
      question: "What is the first step in the scientific method?",
      options: [
        "Formulating a hypothesis",
        "Conducting an experiment",
        "Making an observation",
        "Analyzing data",
      ],
      correctAnswer: "Making an observation",
      explanation:
        "The first step in the scientific method is to make an observation that leads to a question.",
    },
  },
  {
    suggestion: "Study States of Matter",
    sampleMCQ: {
      question:
        "Which state of matter has a definite volume but no definite shape?",
      options: ["Solid", "Liquid", "Gas", "Plasma"],
      correctAnswer: "Liquid",
      explanation:
        "Liquids have a definite volume but take the shape of their container.",
    },
  },
  {
    suggestion: "Learn About Photosynthesis",
    sampleMCQ: {
      question: "What is the main product of photosynthesis?",
      options: ["Oxygen", "Carbon Dioxide", "Glucose", "Water"],
      correctAnswer: "Glucose",
      explanation:
        "The primary product of photosynthesis is glucose, which plants use for energy.",
    },
  },
  {
    suggestion: "Understand the Water Cycle",
    sampleMCQ: {
      question:
        "Which process involves water vapor cooling and forming clouds?",
      options: ["Evaporation", "Condensation", "Precipitation", "Runoff"],
      correctAnswer: "Condensation",
      explanation:
        "Condensation occurs when water vapor cools and changes into liquid droplets, forming clouds.",
    },
  },
  {
    suggestion: "Study Human Anatomy",
    sampleMCQ: {
      question:
        "Which organ is responsible for pumping blood throughout the body?",
      options: ["Lungs", "Liver", "Heart", "Kidneys"],
      correctAnswer: "Heart",
      explanation:
        "The heart pumps blood through the circulatory system, providing oxygen to the body.",
    },
  },
  {
    suggestion: "Learn About Electricity",
    sampleMCQ: {
      question: "Which particle carries a negative charge in an atom?",
      options: ["Proton", "Neutron", "Electron", "Photon"],
      correctAnswer: "Electron",
      explanation:
        "Electrons are negatively charged particles found in the outer shells of atoms.",
    },
  },
  {
    suggestion: "Study Ecosystems and Biodiversity",
    sampleMCQ: {
      question:
        "What term describes a species that no longer has any living individuals?",
      options: ["Endangered", "Extinct", "Threatened", "Vulnerable"],
      correctAnswer: "Extinct",
      explanation:
        "A species is considered extinct when there are no surviving individuals.",
    },
  },
  {
    suggestion: "Learn About Newton’s Laws",
    sampleMCQ: {
      question:
        "Which of Newton's laws states that for every action, there is an equal and opposite reaction?",
      options: [
        "First Law of Motion",
        "Second Law of Motion",
        "Third Law of Motion",
        "Law of Gravitation",
      ],
      correctAnswer: "Third Law of Motion",
      explanation:
        "Newton's Third Law states that for every action, there is an equal and opposite reaction.",
    },
  },
  {
    suggestion: "Understand Chemical Reactions",
    sampleMCQ: {
      question:
        "What type of reaction occurs when two elements combine to form a compound?",
      options: [
        "Synthesis reaction",
        "Decomposition reaction",
        "Combustion reaction",
        "Neutralization reaction",
      ],
      correctAnswer: "Synthesis reaction",
      explanation:
        "In a synthesis reaction, two or more elements or compounds combine to form a more complex product.",
    },
  },
  {
    suggestion: "Study Genetics and Heredity",
    sampleMCQ: {
      question: "What is the shape of a DNA molecule?",
      options: [
        "Single helix",
        "Double helix",
        "Triple helix",
        "Linear strand",
      ],
      correctAnswer: "Double helix",
      explanation:
        "DNA has a double helix structure, consisting of two strands wound around each other.",
    },
  },
];
