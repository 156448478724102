// Cultural Studies - list of subtopics and related MCQs
export const culturalStudiesTopics = [
  {
    suggestion: "Understand the basics of cultural theory",
    sampleMCQ: {
      question:
        "Which term refers to the shared beliefs, customs, and behaviors of a group of people?",
      options: ["Ethnocentrism", "Subculture", "Culture", "Acculturation"],
      correctAnswer: "Culture",
      explanation:
        "Culture refers to the shared beliefs, customs, values, behaviors, and artifacts that define a group of people, influencing their way of life and social interactions.",
    },
  },
  {
    suggestion: "Learn about the concept of cultural identity",
    sampleMCQ: {
      question: "Which of the following best defines cultural identity?",
      options: [
        "The ability to adopt any cultural practice",
        "A feeling of belonging to a particular cultural group",
        "A group’s overall history",
        "The ability to speak multiple languages",
      ],
      correctAnswer: "A feeling of belonging to a particular cultural group",
      explanation:
        "Cultural identity refers to an individual’s sense of belonging to a particular cultural group, shaped by language, customs, traditions, and shared experiences.",
    },
  },
  {
    suggestion: "Explore the concept of multiculturalism",
    sampleMCQ: {
      question: "What does multiculturalism advocate for in a society?",
      options: [
        "Cultural uniformity",
        "Cultural diversity and inclusion",
        "Economic development",
        "Political stability",
      ],
      correctAnswer: "Cultural diversity and inclusion",
      explanation:
        "Multiculturalism advocates for the recognition and inclusion of diverse cultural traditions within a society, promoting respect for different ethnic, racial, and cultural groups.",
    },
  },
  {
    suggestion: "Understand the role of media in shaping culture",
    sampleMCQ: {
      question:
        "Which term refers to the domination of one culture over others through media and consumer products?",
      options: [
        "Cultural relativism",
        "Cultural imperialism",
        "Cultural diffusion",
        "Cultural hybridity",
      ],
      correctAnswer: "Cultural imperialism",
      explanation:
        "Cultural imperialism refers to the domination of one culture over others, often through media, consumer products, and the globalization of cultural values, leading to the spread of one dominant culture.",
    },
  },
  {
    suggestion: "Learn about the concept of subcultures",
    sampleMCQ: {
      question: "Which of the following best defines a subculture?",
      options: [
        "A large dominant culture",
        "A culture that exists in opposition to the dominant culture",
        "A smaller group within a larger culture with distinct values and behaviors",
        "A group that rejects all cultural norms",
      ],
      correctAnswer:
        "A smaller group within a larger culture with distinct values and behaviors",
      explanation:
        "A subculture is a group within a larger culture that has distinct values, behaviors, and norms that differentiate it from the mainstream culture, while still being part of the larger society.",
    },
  },
  {
    suggestion: "Explore the impact of globalization on cultural practices",
    sampleMCQ: {
      question:
        "Which of the following is a common effect of globalization on cultures?",
      options: [
        "Increased cultural isolation",
        "Homogenization of global cultures",
        "Elimination of local traditions",
        "Decreased communication between cultures",
      ],
      correctAnswer: "Homogenization of global cultures",
      explanation:
        "Globalization often leads to the homogenization of cultures, where global cultural practices and values become more similar, sometimes at the expense of local and traditional cultures.",
    },
  },
  {
    suggestion: "Understand the concept of cultural appropriation",
    sampleMCQ: {
      question: "Which of the following best describes cultural appropriation?",
      options: [
        "Sharing cultural practices with others",
        "The respectful exchange of cultural elements",
        "Adopting elements of another culture without permission or understanding",
        "Creating new cultural traditions",
      ],
      correctAnswer:
        "Adopting elements of another culture without permission or understanding",
      explanation:
        "Cultural appropriation refers to the adoption of elements of a minority or marginalized culture by members of a dominant culture, often without understanding or respect for the cultural significance.",
    },
  },
  {
    suggestion: "Learn about the concept of cultural relativism",
    sampleMCQ: {
      question:
        "What does cultural relativism suggest about understanding other cultures?",
      options: [
        "All cultures should be judged by a single standard",
        "Cultural practices should be understood in their own context",
        "One culture is superior to others",
        "Cultural change should be resisted",
      ],
      correctAnswer:
        "Cultural practices should be understood in their own context",
      explanation:
        "Cultural relativism suggests that cultural practices should be understood and evaluated within the context of that culture, rather than being judged by external standards.",
    },
  },
  {
    suggestion: "Explore the role of language in shaping culture",
    sampleMCQ: {
      question:
        "Which theory suggests that language shapes the way we perceive and understand the world?",
      options: [
        "Linguistic relativism",
        "Social learning theory",
        "Cognitive theory",
        "Behaviorism",
      ],
      correctAnswer: "Linguistic relativism",
      explanation:
        "Linguistic relativism, also known as the Sapir-Whorf hypothesis, suggests that the structure of a language affects how its speakers perceive and understand the world, influencing cultural norms and values.",
    },
  },
  {
    suggestion: "Understand the concept of postcolonialism",
    sampleMCQ: {
      question: "What is a key focus of postcolonial studies?",
      options: [
        "The history of ancient civilizations",
        "The influence of colonialism on former colonies and their cultures",
        "The study of modern globalization",
        "The development of new political systems",
      ],
      correctAnswer:
        "The influence of colonialism on former colonies and their cultures",
      explanation:
        "Postcolonial studies examine the cultural, social, and political impacts of colonialism on former colonies, including the ways in which colonialism shaped identities and power structures in those societies.",
    },
  },
  {
    suggestion: "Learn about the role of gender in cultural studies",
    sampleMCQ: {
      question:
        "Which term refers to the socially constructed roles and behaviors associated with being male or female?",
      options: ["Sexual orientation", "Gender", "Biological sex", "Ethnicity"],
      correctAnswer: "Gender",
      explanation:
        "Gender refers to the socially constructed roles, behaviors, and attributes that a society considers appropriate for men, women, and non-binary individuals, as opposed to biological sex, which is assigned at birth.",
    },
  },
  {
    suggestion: "Explore the concept of identity politics",
    sampleMCQ: {
      question:
        "Which of the following is a primary goal of identity politics?",
      options: [
        "To maintain social hierarchies",
        "To promote the interests of specific groups based on shared characteristics",
        "To eliminate all social differences",
        "To focus on economic issues over social concerns",
      ],
      correctAnswer:
        "To promote the interests of specific groups based on shared characteristics",
      explanation:
        "Identity politics is a framework in which groups with shared characteristics, such as race, gender, or sexual orientation, advocate for their rights and interests within the broader political system.",
    },
  },
  {
    suggestion: "Understand the concept of cultural hybridity",
    sampleMCQ: {
      question: "What is cultural hybridity?",
      options: [
        "The blending of elements from different cultures to create new cultural forms",
        "The domination of one culture over another",
        "The rejection of all cultural norms",
        "The preservation of traditional cultures in isolation",
      ],
      correctAnswer:
        "The blending of elements from different cultures to create new cultural forms",
      explanation:
        "Cultural hybridity refers to the blending of elements from different cultures to create new, hybrid cultural forms, often resulting from interactions between global and local influences.",
    },
  },
  {
    suggestion: "Learn about the role of ethnicity in cultural identity",
    sampleMCQ: {
      question: "Which of the following best defines ethnicity?",
      options: [
        "A person's nationality",
        "A person's religious affiliation",
        "A shared cultural heritage based on common ancestry, language, or traditions",
        "A person's political beliefs",
      ],
      correctAnswer:
        "A shared cultural heritage based on common ancestry, language, or traditions",
      explanation:
        "Ethnicity refers to a shared cultural heritage, including common ancestry, language, customs, and traditions, that helps define a group's identity within a larger society.",
    },
  },
];
