// Humanities - list of subtopics and related MCQs
export const humanitiesTopics = [
  {
    suggestion: "Explore the fundamentals of philosophy",
    sampleMCQ: {
      question: "Who is considered the 'father of Western philosophy'?",
      options: ["Socrates", "Plato", "Aristotle", "Descartes"],
      correctAnswer: "Socrates",
      explanation:
        "Socrates is often regarded as the 'father of Western philosophy' due to his influence on philosophical thought and the Socratic method, a form of cooperative argumentative dialogue.",
    },
  },
  {
    suggestion: "Understand the major literary movements",
    sampleMCQ: {
      question:
        "Which literary movement is known for emphasizing emotion and individualism?",
      options: ["Realism", "Romanticism", "Modernism", "Naturalism"],
      correctAnswer: "Romanticism",
      explanation:
        "Romanticism is a literary movement that emphasized emotion, individualism, and the glorification of nature, and was prominent in the late 18th and early 19th centuries.",
    },
  },
  {
    suggestion: "Learn about key figures in Renaissance art",
    sampleMCQ: {
      question: "Which artist painted the 'Mona Lisa'?",
      options: ["Michelangelo", "Raphael", "Leonardo da Vinci", "Donatello"],
      correctAnswer: "Leonardo da Vinci",
      explanation:
        "Leonardo da Vinci, a key figure of the Italian Renaissance, painted the 'Mona Lisa,' one of the most famous works of art in history.",
    },
  },
  {
    suggestion: "Explore the impact of major world religions",
    sampleMCQ: {
      question:
        "Which religion is based on the teachings of Siddhartha Gautama?",
      options: ["Christianity", "Hinduism", "Buddhism", "Islam"],
      correctAnswer: "Buddhism",
      explanation:
        "Buddhism is based on the teachings of Siddhartha Gautama, also known as the Buddha, who taught about overcoming suffering through the Eightfold Path and attaining enlightenment.",
    },
  },
  {
    suggestion: "Understand key philosophical concepts such as ethics",
    sampleMCQ: {
      question:
        "Which branch of philosophy is concerned with morality and the principles of right and wrong?",
      options: ["Epistemology", "Ethics", "Metaphysics", "Aesthetics"],
      correctAnswer: "Ethics",
      explanation:
        "Ethics is the branch of philosophy that deals with questions of morality and examines what is right and wrong in human behavior.",
    },
  },
  {
    suggestion: "Learn about the evolution of theater and drama",
    sampleMCQ: {
      question:
        "Which of the following is a type of play that originated in ancient Greece and deals with serious themes?",
      options: ["Comedy", "Tragedy", "Farce", "Satire"],
      correctAnswer: "Tragedy",
      explanation:
        "Tragedy is a genre of play that originated in ancient Greece, dealing with serious themes and often involving the downfall of the main character due to a tragic flaw.",
    },
  },
  {
    suggestion: "Explore the influence of classical music composers",
    sampleMCQ: {
      question:
        "Which composer is known for composing the opera 'The Magic Flute'?",
      options: [
        "Ludwig van Beethoven",
        "Wolfgang Amadeus Mozart",
        "Johann Sebastian Bach",
        "Franz Schubert",
      ],
      correctAnswer: "Wolfgang Amadeus Mozart",
      explanation:
        "Wolfgang Amadeus Mozart, a key figure in classical music, composed the famous opera 'The Magic Flute,' among many other notable works.",
    },
  },
  {
    suggestion: "Understand the development of world literature",
    sampleMCQ: {
      question:
        "Which novel by Leo Tolstoy explores themes of love, family, and society in Russia?",
      options: [
        "War and Peace",
        "Anna Karenina",
        "The Brothers Karamazov",
        "Crime and Punishment",
      ],
      correctAnswer: "Anna Karenina",
      explanation:
        "Leo Tolstoy's 'Anna Karenina' is a classic novel that explores complex themes of love, family, and the social fabric of 19th-century Russian society.",
    },
  },
  {
    suggestion:
      "Learn about the significance of mythology in different cultures",
    sampleMCQ: {
      question: "In Greek mythology, who is the god of the sea?",
      options: ["Zeus", "Poseidon", "Hades", "Apollo"],
      correctAnswer: "Poseidon",
      explanation:
        "Poseidon is the god of the sea in Greek mythology, often depicted carrying a trident and associated with horses and earthquakes.",
    },
  },
  {
    suggestion: "Explore the history and influence of architecture",
    sampleMCQ: {
      question:
        "Which architectural style is characterized by pointed arches and flying buttresses?",
      options: ["Baroque", "Gothic", "Romanesque", "Renaissance"],
      correctAnswer: "Gothic",
      explanation:
        "Gothic architecture, prominent in the Middle Ages, is known for its pointed arches, flying buttresses, and large stained glass windows, as seen in cathedrals like Notre-Dame.",
    },
  },
  {
    suggestion: "Understand the key aspects of cultural studies",
    sampleMCQ: {
      question:
        "Which concept refers to the spread of cultural elements from one society to another?",
      options: [
        "Cultural diffusion",
        "Ethnocentrism",
        "Cultural relativism",
        "Assimilation",
      ],
      correctAnswer: "Cultural diffusion",
      explanation:
        "Cultural diffusion is the process by which cultural elements, such as ideas, customs, or technologies, spread from one society to another.",
    },
  },
  {
    suggestion: "Learn about the Renaissance period and its significance",
    sampleMCQ: {
      question: "The Renaissance is often referred to as a rebirth of what?",
      options: [
        "Classical art and learning",
        "Religious devotion",
        "Political stability",
        "Agricultural practices",
      ],
      correctAnswer: "Classical art and learning",
      explanation:
        "The Renaissance is known as a rebirth of classical art, literature, and learning, drawing inspiration from the ancient civilizations of Greece and Rome.",
    },
  },
  {
    suggestion:
      "Explore the history of the Enlightenment and its impact on society",
    sampleMCQ: {
      question:
        "Which philosopher is known for the statement 'I think, therefore I am'?",
      options: [
        "Immanuel Kant",
        "Jean-Jacques Rousseau",
        "John Locke",
        "René Descartes",
      ],
      correctAnswer: "René Descartes",
      explanation:
        "René Descartes, a key figure in the Enlightenment, is known for the statement 'Cogito, ergo sum' (I think, therefore I am), which reflects his emphasis on reason and doubt as fundamental to knowledge.",
    },
  },
  {
    suggestion: "Learn about art movements such as Impressionism",
    sampleMCQ: {
      question:
        "Which artist is often credited with founding the Impressionist movement?",
      options: [
        "Vincent van Gogh",
        "Claude Monet",
        "Pablo Picasso",
        "Edgar Degas",
      ],
      correctAnswer: "Claude Monet",
      explanation:
        "Claude Monet is often credited with founding the Impressionist movement, particularly with his painting 'Impression, Sunrise,' which gave the movement its name.",
    },
  },
];
