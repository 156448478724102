import React from "react";

import {
  Box,
  Typography,
  Container,
  Button,
  Link,
  AppBar,
  Toolbar,
} from "@mui/material";
import Lottie from "react-lottie";
import rocketman from "./animations/rocketman.json";
import QuizSyncText from "./QuizSyncText";

const LandingPage = () => {
  const handleLogin = () => {
    window.location.href = "/login";
  };

  const challenges = [
    {
      title: "Engaging Every Student",
      description:
        "In traditional classrooms, it's difficult to ensure every student is actively participating and fully understanding the material. Teachers often struggle to keep all students engaged simultaneously.",
    },
    {
      title: "Monitoring Overall Learning and Individual Progress",
      description:
        "Teachers need to effectively monitor both the overall learning trends in the classroom and the individual progress of each student to ensure that no one is left behind.",
    },
    {
      title: "Time-Consuming Quiz Setup",
      description:
        "Creating effective quizzes that assess student understanding can be time-consuming, especially when covering a wide range of subjects.",
    },
  ];

  const solutions = [
    {
      title: "AI-Powered Quiz Creation",
      solution:
        "Setup quizzes instantly for any subject using our Generative AI. Save time and effort while ensuring your quizzes are relevant and effective.",
    },
    {
      title: "Interactive Classrooms",
      solution:
        "Foster engagement by enabling every student to interact with the teacher in real-time. Our platform transforms traditional lectures into dynamic, two-way conversations.",
    },
    {
      title: "Holistic Classroom & Student Performance Tracker",
      solution:
        "QuizSync offers a Holistic Classroom & Student Performance Tracker, providing real-time insights into both overall classroom comprehension and individual student progress, allowing educators to tailor their teaching strategies to meet the needs of all learners.",
    },
  ];

  const benefits = [
    {
      title: "Improved Student Learning and Achievement",
      description: `"The evidence is clear: formative assessment is one of the most powerful ways to enhance student learning."`,
      source: "— Black & Wiliam, Inside the Black Box (1998)",
    },
    {
      title: "Increased Student Engagement and Motivation",
      description: `"Formative assessment creates a learning environment where students are more engaged and motivated to improve."`,
      source:
        "— Nicol & Macfarlane-Dick, Formative assessment and self-regulated learning (2006)",
    },
    {
      title: "Enhanced Teacher Effectiveness",
      description: `"Feedback through formative assessment is among the most effective strategies for improving student achievement."`,
      source: "— John Hattie, Visible Learning (2009)",
    },
    {
      title: "Reduction of Achievement Gaps",
      description: `"Formative assessment is instrumental in narrowing achievement gaps, especially for students who are at risk of falling behind."`,
      source:
        "— Shavelson et al., On the Impact of Formative Assessment on Student Achievement (2008)",
    },
    {
      title: "Supports a Growth Mindset",
      description: `"When students are encouraged to focus on improvement and growth, they develop a mindset that drives lifelong learning."`,
      source: "— Carol Dweck, Mindset: The New Psychology of Success (2006)",
    },
    {
      title: "Better Retention and Understanding of Material",
      description: `"Regular formative assessments help students retain and deeply understand the material."`,
      source:
        "— Pashler et al., Organizing Instruction and Study to Improve Student Learning (2007)",
    },
  ];

  const earlyAdopterBenefits = [
    {
      title: "Exclusive Early Access",
      description:
        "Be among the first to experience QuizSync and gain exclusive access to new features before they are publicly released.",
    },
    {
      title: "Shape the Future",
      description:
        "Your feedback will directly influence the development of QuizSync, helping us create the best tools for educators.",
    },
    {
      title: "Contact Us Directly",
      description: (
        <span>
          Reach out to us via{" "}
          <Link href="mailto:jjitesh@gmail.com" color="secondary">
            email
          </Link>{" "}
          or connect with us on{" "}
          <Link
            href="https://www.linkedin.com/in/jiteshganage"
            color="secondary"
            target="_blank"
            rel="noopener"
          >
            LinkedIn
          </Link>{" "}
          to join our early adopter program.
        </span>
      ),
    },
  ];

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: rocketman,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Typography variant="caption" display="block">
              <QuizSyncText>QuizSync</QuizSyncText>
            </Typography>
            <Box>
              <Button color="inherit" onClick={handleLogin}>
                LOGIN
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      {/* Section 1: Hero Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: { xs: "60vh", md: "50vh" }, // Adjust height for mobile
          textAlign: "center",
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          padding: { xs: 2, md: 3 }, // Adjust padding for mobile
          marginTop: 0,
          paddingTop: 0,
        }}
      >
        {/* Logo */}
        <img
          src="/logo512.png"
          alt="<QuizSyncText>QuizSync</QuizSyncText> Logo"
          style={{ width: "80px", marginBottom: "16px" }} // Adjust logo size for mobile
        />

        <Container maxWidth="md">
          <Typography
            variant="h4" // Reduce font size for mobile
            gutterBottom
            sx={{
              fontSize: { xs: "1.75rem", md: "2.125rem" }, // Responsive font size
            }}
          >
            Transform Your Classroom with <QuizSyncText>QuizSync</QuizSyncText>
          </Typography>
          <Typography
            variant="h6" // Reduce font size for mobile
            gutterBottom
            sx={{ fontSize: { xs: "1.125rem", md: "1.5rem" } }} // Responsive font size
          >
            AI-powered quizzes, real-time student-teacher engagement, and
            analytics
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            onClick={handleLogin}
            sx={{ mt: 4 }}
            size="large"
          >
            GET STARTED
          </Button>
        </Container>
      </Box>

      {/* Section 2: The Challenges */}
      <Box
        sx={{
          py: { xs: 6, md: 10 }, // Adjust padding for mobile
          backgroundColor: "#f4f4f9", // Light grey background
          color: "text.primary",
          textAlign: "center",
        }}
      >
        <Container maxWidth="md">
          {/* Everest Image */}
          <Box sx={{ mb: 4 }}>
            <img
              src="/everest.png"
              alt="Everest"
              style={{ width: "20%", maxWidth: "400px", margin: "0 auto" }}
            />
          </Box>

          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
          >
            The Challenges
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ fontSize: { xs: "1rem", md: "1.125rem" } }}
          >
            We understand the challenges teachers face every day, and we're here
            to help.
          </Typography>

          <Box sx={{ mt: 4 }}>
            {challenges.map((item, index) => (
              <Box
                key={index}
                sx={{
                  mb: { xs: 3, md: 5 },
                  textAlign: "left",
                  borderLeft: "4px solid",
                  borderColor: "primary.main",
                  pl: 2,
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontSize: { xs: "1.25rem", md: "1.5rem" } }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: { xs: "0.875rem", md: "1rem" } }}
                >
                  {item.description}
                </Typography>
              </Box>
            ))}
          </Box>
        </Container>
      </Box>

      {/* Section 3: Your Teaching Superpowers */}
      <Box
        sx={{
          py: { xs: 6, md: 10 }, // Adjust padding for mobile
          backgroundColor: "#e3f2fd", // Light blue background
          color: "text.primary",
          textAlign: "center",
        }}
      >
        <Container maxWidth="md">
          {/* Rocketman Animation */}
          <Box sx={{ mb: 4 }}>
            <Lottie options={defaultOptions} height={300} width={300} />
          </Box>

          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
          >
            Your Teaching Superpowers
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ fontSize: { xs: "1rem", md: "1.125rem" } }}
          >
            Here's how <QuizSyncText>QuizSync</QuizSyncText> helps you overcome
            these challenges and unlock your full potential.
          </Typography>

          <Box sx={{ mt: 4 }}>
            {solutions.map((item, index) => (
              <Box
                key={index}
                sx={{
                  mb: { xs: 3, md: 5 },
                  textAlign: "left",
                  borderLeft: "4px solid",
                  borderColor: "secondary.main",
                  pl: 2,
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontSize: { xs: "1.25rem", md: "1.5rem" } }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: { xs: "0.875rem", md: "1rem" } }}
                >
                  {item.solution}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box sx={{ mt: 6 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontSize: { xs: "1.25rem", md: "1.5rem" } }}
            >
              "Since using <QuizSyncText>QuizSync</QuizSyncText>, I've regained
              valuable time, and my students are more engaged than ever. It's
              truly transformed my teaching experience."
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: { xs: "0.875rem", md: "1rem" } }}
            >
              — Sarah T., High School Teacher
            </Typography>
          </Box>
        </Container>
      </Box>

      {/* Section 4: Why Formative Assessment Works */}
      <Box
        sx={{
          py: { xs: 6, md: 10 }, // Adjust padding for mobile
          backgroundColor: "#ffecb3", // Light yellow background
          color: "text.primary",
          textAlign: "center",
        }}
      >
        <Container maxWidth="md">
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
          >
            Why Formative Assessment Works: Research-Backed Benefits
          </Typography>

          <Box sx={{ mt: 4 }}>
            {benefits.map((item, index) => (
              <Box
                key={index}
                sx={{
                  mb: { xs: 3, md: 5 },
                  textAlign: "left",
                  borderLeft: "4px solid",
                  borderColor: "primary.main",
                  pl: 2,
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontSize: { xs: "1.25rem", md: "1.5rem" } }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: { xs: "0.875rem", md: "1rem" } }}
                >
                  {item.description}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: { xs: "0.75rem", md: "0.875rem" }, mt: 2 }}
                >
                  {item.source}
                </Typography>
              </Box>
            ))}
          </Box>
        </Container>
      </Box>

      {/* Section 5: Join Our Early Adopters */}
      <Box
        sx={{
          py: { xs: 6, md: 10 }, // Adjust padding for mobile
          backgroundColor: "#d1c4e9", // Light purple background
          color: "text.primary",
          textAlign: "center",
        }}
      >
        <Container maxWidth="md">
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
          >
            Get in Touch
            <QuizSyncText />
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ fontSize: { xs: "1rem", md: "1.125rem" }, mb: 4 }}
          >
            We're looking for passionate educators to be a part of our journey.
            Your insights will help us build a platform that truly meets the
            needs of teachers and students alike.
          </Typography>

          <Box sx={{ mt: 4 }}>
            {earlyAdopterBenefits.map((item, index) => (
              <Box
                key={index}
                sx={{
                  mb: { xs: 3, md: 5 },
                  textAlign: "left",
                  borderLeft: "4px solid",
                  borderColor: "secondary.main",
                  pl: 2,
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontSize: { xs: "1.25rem", md: "1.5rem" } }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: { xs: "0.875rem", md: "1rem" } }}
                >
                  {item.description}
                </Typography>
              </Box>
            ))}
          </Box>

          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 6 }}
            size="large"
            href="mailto:your-email@example.com"
          >
            Contact Us Now
          </Button>
        </Container>
      </Box>

      {/* Section 6: Footer */}
      <Box
        sx={{
          py: { xs: 3, md: 5 }, // Adjust padding for mobile
          backgroundColor: "#333",
          color: "#fff",
          textAlign: "center",
          mt: 0, // Margin top
        }}
      >
        <Container maxWidth="md">
          <Typography variant="body2" gutterBottom>
            <Link href="#" color="inherit" underline="hover">
              Privacy Policy
            </Link>{" "}
            |{" "}
            <Link href="#" color="inherit" underline="hover">
              Terms and Conditions
            </Link>
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            <QuizSyncText>QuizSync</QuizSyncText> is an interactivelearning.one
            application.
          </Typography>

          <Typography variant="body2" sx={{ mt: 1 }}>
            © 2024 QuizSync. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default LandingPage;
