import React, { useCallback } from "react";
import {
  Box,
  IconButton,
  Menu,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Avatar from "@mui/material/Avatar";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

const UserMenu = ({
  socket,
  avatar,
  anchorEl,
  userInfo,
  themeMode,
  toggleThemeMode,
  setUserInfo,
  setAnchorEl,
  setParticipantId,
  setIsLoggedIn,
  
}) => {
  const handleLogout = useCallback(() => {
    setIsLoggedIn(false);
    setUserInfo(null);
    setParticipantId(null);
    localStorage.removeItem("authToken");
    socket.emit("logout");
    window.location.href = "/login";
  }, [setIsLoggedIn, setUserInfo, setParticipantId, socket]);

  const handleMenuClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <>
      <IconButton color="inherit" onClick={handleMenuClick}>
        {avatar ? (
          <Avatar src={avatar} alt="User Avatar" />
        ) : (
          <AccountCircleIcon />
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{ sx: { padding: 2, borderRadius: 2, boxShadow: 3 } }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: 1 }}>
            {userInfo.name}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            {userInfo.role}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {userInfo.email}
          </Typography>
        </Box>
        <Divider light sx={{ mb: 2 }} />
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <IconButton color="inherit" onClick={toggleThemeMode}>
            {themeMode === "light" ? <Brightness4Icon /> : <Brightness7Icon />}
          </IconButton>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleLogout();
            handleMenuClose();
          }}
          fullWidth
        >
          Logout
        </Button>
      </Menu>
    </>
  );
};

export default UserMenu;
