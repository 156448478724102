import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Grid,
  List,
  ListItem,
  Avatar,
  Paper,
  ListItemText,
  Tabs,
  Tab,
  AvatarGroup,
  LinearProgress,
} from "@mui/material";
import Lottie from "react-lottie";
import correctAnimation from "./animations/correctAnswer.json";
import incorrectAnimation from "./animations/incorrectAnswer.json";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const HostView = ({
  title,
  quizCode,
  totalParticipants,
  currentQuestion,
  currentQuestionIndex,
  totalQuestions,
  results,
  answer,
  hasSubmitted,
  correctAnswer,
  explanation,
  showAnswers,
  responses,
  participants,
  scores,
  isHost,
  handleNextQuestion,
  handleSubmitAnswer,
  setAnswer,
  participantId,
  scoreGrid,
}) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Sort participants based on their score (correct answers)
  const sortedParticipants = participants.sort((a, b) => {
    const scoreA = scores[a.id]?.correct || 0;
    const scoreB = scores[b.id]?.correct || 0;
    return scoreB - scoreA;
  });

  return (
    <Box sx={{ width: "100%", maxWidth: "1000px", mx: "auto" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      ></Box>
      <Card elevation={3} sx={{ position: "relative", width: "100%" }}>
        <CardContent>
          {showAnswers && answer === correctAnswer && (
            <Box sx={{ position: "absolute", top: 5, right: 8 }}>
              <Lottie
                options={{ animationData: correctAnimation }}
                height={50}
                width={50}
              />
            </Box>
          )}
          {showAnswers && answer !== correctAnswer && (
            <Box sx={{ position: "absolute", top: 5, right: 28 }}>
              <Lottie
                options={{ animationData: incorrectAnimation }}
                height={50}
                width={50}
              />
            </Box>
          )}
          <Box sx={{ width: "100%" }}>
            <Typography variant="body1">
              Question {currentQuestionIndex + 1} of {totalQuestions}
            </Typography>
            {currentQuestion && (
              <Box mt={2}>
                <Typography variant="h6">{currentQuestion.question}</Typography>
                <Grid container spacing={2} mt={1}>
                  {currentQuestion.options.map((option) => {
                    const count = results[option] || 0;
                    const percentage =
                      totalParticipants > 0
                        ? ((count / totalParticipants) * 100).toFixed(2)
                        : 0;
                    return (
                      <Grid item xs={12} sm={6} key={option}>
                        <Button
                          variant={answer === option ? "contained" : "outlined"}
                          color={
                            showAnswers && option === correctAnswer
                              ? "success"
                              : "primary"
                          }
                          onClick={() => setAnswer(option)}
                          fullWidth
                          disabled={hasSubmitted || showAnswers}
                          sx={{
                            border:
                              showAnswers && option === correctAnswer
                                ? "2px solid green"
                                : showAnswers && answer === option
                                  ? "2px solid red"
                                  : undefined,
                            fontWeight:
                              showAnswers && option === correctAnswer
                                ? "bold"
                                : undefined,
                          }}
                        >
                          {option}
                          {showAnswers && option === correctAnswer && " ✓"}
                        </Button>
                        <Typography variant="body2" align="center">
                          {count} responses ({percentage}%)
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            )}
            <Box mt={2}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2" sx={{ mr: 1 }}>
                  Responses:
                </Typography>
                <Box sx={{ width: "80%", bgcolor: "#e0e0e0", borderRadius: 1 }}>
                  <Box
                    sx={{
                      width: `${(responses / totalParticipants) * 100}%`,
                      bgcolor: "primary.main",
                      height: 10,
                      borderRadius: 1,
                    }}
                  />
                </Box>
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {responses} / {totalParticipants}
                </Typography>
              </Box>
            </Box>
            {showAnswers && (
              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                Explanation: {explanation}
              </Typography>
            )}
          </Box>
        </CardContent>
        <CardActions>
          {!showAnswers && !hasSubmitted && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmitAnswer}
              disabled={!answer}
              fullWidth
            >
              Submit Answer
            </Button>
          )}
          {showAnswers && isHost && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextQuestion}
              fullWidth
            >
              Next Question
            </Button>
          )}
        </CardActions>
      </Card>
      <Paper elevation={3} sx={{ mt: 2, p: 2, width: "100%" }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs">
          <Tab label="Aggregate View" />
          <Tab label="Detailed Analysis" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <AvatarGroup max={6} sx={{ mr: 2 }}>
              {participants.map((participant) => (
                <Avatar
                  key={participant.id}
                  src={participant.avatar}
                  alt={`${participant.name} Avatar`}
                />
              ))}
            </AvatarGroup>
            <Typography variant="body1" gutterBottom>
              {totalParticipants} Participants
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {currentQuestion.options.map((option) => {
              const count = results[option] || 0;
              const percentage =
                totalParticipants > 0
                  ? ((count / totalParticipants) * 100).toFixed(2)
                  : 0;

              return (
                <Grid item xs={12} key={option}>
                  <Box
                    sx={{
                      border: "1px solid lightgray", // Added light border here
                      borderRadius: 1,
                      padding: 1,
                    }}
                  >
                    <Typography variant="body1">{option}</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 1,
                        mb: 2,
                      }}
                    >
                      <Box
                        sx={{
                          width: `${percentage}%`,
                          bgcolor:
                            showAnswers && option === correctAnswer
                              ? "green"
                              : "red",
                          height: 20,
                          borderRadius: 1,
                          transition: "width 0.5s",
                        }}
                      />
                      <Typography
                        variant="body2"
                        sx={{ ml: 2 }}
                      >{`${count} responses (${percentage}%)`}</Typography>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <AvatarGroup max={6} sx={{ mr: 2 }}>
              {participants.map((participant) => (
                <Avatar
                  key={participant.id}
                  src={participant.avatar}
                  alt={`${participant.name} Avatar`}
                />
              ))}
            </AvatarGroup>
            <Typography variant="body1" gutterBottom>
              {totalParticipants} Participants
            </Typography>
          </Box>
          <List>
            {sortedParticipants.map((participant, index) => {
              const score = scores[participant.id] || {
                correct: 0,
                incorrect: 0,
                missed: 0,
              };
              const participantScoreGrid = Array.isArray(scoreGrid)
                ? scoreGrid.find(
                    (grid) => grid.participantId === participant.id,
                  )
                : null;
              return (
                <ListItem
                  key={participant.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      src={participant.avatar}
                      alt={`${participant.id} Avatar`}
                      sx={{ mr: 2 }}
                    />
                    <ListItemText
                      primary={
                        participant.id === participantId
                          ? `${participant.name} (${participant.id}) (You)`
                          : `${participant.name} (${participant.id})`
                      }
                      secondary={`Email: ${participant.email}`}
                    />
                  </Box>
                  <Box sx={{ mt: 1, width: "100%" }}>
                    <Typography variant="body2">
                      Correct: {score.correct}, Incorrect: {score.incorrect},
                      Missed: {score.missed}
                    </Typography>
                    {participantScoreGrid && (
                      <div>
                        <Typography variant="body2">Score Grid:</Typography>
                        <Grid container spacing={1} alignItems="center">
                          {participantScoreGrid.scores.map((s, index) => (
                            <Grid item key={index}>
                              {s === "_" && "_"}
                              {s === 0 && "x"}
                              {s === 1 && "✓"}
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    )}
                    <LinearProgress
                      variant="determinate"
                      value={(score.correct / totalQuestions) * 100}
                      sx={{ mt: 1, height: 10 }}
                    />
                    <Typography variant="body2" align="right">
                      {((score.correct / totalQuestions) * 100).toFixed(2)}%
                    </Typography>
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </TabPanel>
      </Paper>
    </Box>
  );
};

export default HostView;
