// Education - list of subtopics and related MCQs
export const educationTopics = [
  {
    suggestion: "Understand the basics of educational psychology",
    sampleMCQ: {
      question:
        "Which theory emphasizes the importance of reinforcement and punishment in learning?",
      options: ["Constructivism", "Behaviorism", "Cognitivism", "Humanism"],
      correctAnswer: "Behaviorism",
      explanation:
        "Behaviorism is a theory of learning that emphasizes the role of reinforcement (rewards) and punishment in shaping behavior, with key figures like B.F. Skinner and John Watson.",
    },
  },
  {
    suggestion: "Learn about different learning styles",
    sampleMCQ: {
      question:
        "Which learning style involves learning best through hands-on experience?",
      options: [
        "Auditory learning",
        "Visual learning",
        "Kinesthetic learning",
        "Verbal learning",
      ],
      correctAnswer: "Kinesthetic learning",
      explanation:
        "Kinesthetic learners prefer to learn through hands-on experiences, such as movement, physical activities, and doing tasks themselves.",
    },
  },
  {
    suggestion: "Explore the fundamentals of curriculum development",
    sampleMCQ: {
      question:
        "Which of the following refers to the set of courses and their content offered at a school or university?",
      options: ["Syllabus", "Pedagogy", "Curriculum", "Assessment"],
      correctAnswer: "Curriculum",
      explanation:
        "The curriculum refers to the structured set of courses and content offered by a school or university, defining what students are expected to learn in each subject.",
    },
  },
  {
    suggestion: "Understand the role of assessments in education",
    sampleMCQ: {
      question:
        "Which type of assessment is used to evaluate student learning at the end of a unit or course?",
      options: [
        "Formative assessment",
        "Summative assessment",
        "Diagnostic assessment",
        "Continuous assessment",
      ],
      correctAnswer: "Summative assessment",
      explanation:
        "Summative assessments are typically conducted at the end of a unit or course to evaluate students' understanding of the material, such as final exams or end-of-term projects.",
    },
  },
  {
    suggestion: "Learn about differentiated instruction in classrooms",
    sampleMCQ: {
      question:
        "Which of the following best describes differentiated instruction?",
      options: [
        "Teaching all students the same way",
        "Adapting teaching methods to meet the needs of diverse learners",
        "Providing only written assessments",
        "Grouping students by ability only",
      ],
      correctAnswer:
        "Adapting teaching methods to meet the needs of diverse learners",
      explanation:
        "Differentiated instruction involves adapting teaching methods, materials, and assessments to meet the diverse needs, strengths, and learning styles of students.",
    },
  },
  {
    suggestion: "Explore the principles of child development",
    sampleMCQ: {
      question:
        "Which of the following is associated with Jean Piaget’s theory of cognitive development?",
      options: [
        "Zones of proximal development",
        "Stages of cognitive development",
        "Classical conditioning",
        "Multiple intelligences",
      ],
      correctAnswer: "Stages of cognitive development",
      explanation:
        "Jean Piaget's theory of cognitive development outlines four stages: sensorimotor, preoperational, concrete operational, and formal operational, each representing different ways that children think and understand the world.",
    },
  },
  {
    suggestion: "Learn about the concept of inclusive education",
    sampleMCQ: {
      question: "What is the primary goal of inclusive education?",
      options: [
        "To provide specialized schools for students with disabilities",
        "To integrate all students, regardless of ability, into mainstream classrooms",
        "To reduce the number of students in a classroom",
        "To focus only on high-achieving students",
      ],
      correctAnswer:
        "To integrate all students, regardless of ability, into mainstream classrooms",
      explanation:
        "Inclusive education aims to integrate all students, including those with disabilities, into mainstream classrooms, ensuring that every student has access to the same educational opportunities.",
    },
  },
  {
    suggestion: "Understand the impact of technology on modern education",
    sampleMCQ: {
      question:
        "Which of the following is an example of educational technology?",
      options: [
        "Blackboards",
        "Tablets and online learning platforms",
        "Textbooks",
        "Classroom desks",
      ],
      correctAnswer: "Tablets and online learning platforms",
      explanation:
        "Educational technology refers to tools like tablets, computers, and online learning platforms that enhance teaching and learning experiences, making education more interactive and accessible.",
    },
  },
  {
    suggestion: "Explore the history of education systems",
    sampleMCQ: {
      question:
        "Which ancient philosopher founded the Academy in Athens, one of the earliest institutions of higher learning?",
      options: ["Aristotle", "Socrates", "Plato", "Pythagoras"],
      correctAnswer: "Plato",
      explanation:
        "Plato founded the Academy in Athens, which is considered one of the earliest institutions of higher learning in Western civilization, where philosophy and other subjects were studied.",
    },
  },
  {
    suggestion: "Learn about the role of teacher professional development",
    sampleMCQ: {
      question:
        "What is the main purpose of professional development for teachers?",
      options: [
        "To improve teacher salaries",
        "To enhance teacher knowledge and instructional skills",
        "To evaluate student performance",
        "To develop new curricula",
      ],
      correctAnswer: "To enhance teacher knowledge and instructional skills",
      explanation:
        "Professional development for teachers aims to enhance their knowledge, instructional skills, and classroom practices, allowing them to stay current with new teaching strategies and educational research.",
    },
  },
  {
    suggestion: "Understand the basics of classroom management",
    sampleMCQ: {
      question:
        "Which of the following is a key element of effective classroom management?",
      options: [
        "Punishment-based discipline",
        "Establishing clear rules and procedures",
        "Allowing students complete freedom",
        "Reducing teacher involvement",
      ],
      correctAnswer: "Establishing clear rules and procedures",
      explanation:
        "Effective classroom management involves establishing clear rules, routines, and procedures that help create an organized, respectful, and conducive learning environment.",
    },
  },
  {
    suggestion: "Explore the importance of social-emotional learning (SEL)",
    sampleMCQ: {
      question:
        "What does social-emotional learning (SEL) focus on developing in students?",
      options: [
        "Mathematical skills",
        "Social and emotional skills like empathy and self-awareness",
        "Physical fitness",
        "Language proficiency",
      ],
      correctAnswer:
        "Social and emotional skills like empathy and self-awareness",
      explanation:
        "Social-emotional learning (SEL) focuses on developing students' social and emotional skills, such as empathy, self-awareness, relationship-building, and emotional regulation, which are essential for success in school and life.",
    },
  },
  {
    suggestion: "Learn about educational equity and access",
    sampleMCQ: {
      question: "What does educational equity aim to achieve?",
      options: [
        "Equal outcomes for all students",
        "Identical resources for every school",
        "Fair access to educational opportunities regardless of background",
        "Standardized teaching methods for all students",
      ],
      correctAnswer:
        "Fair access to educational opportunities regardless of background",
      explanation:
        "Educational equity focuses on ensuring that all students, regardless of their background, have fair access to high-quality education and the resources they need to succeed.",
    },
  },
  {
    suggestion: "Understand the role of pedagogy in teaching",
    sampleMCQ: {
      question: "What does pedagogy refer to in education?",
      options: [
        "The curriculum used in schools",
        "The theory and practice of teaching",
        "The technology used in classrooms",
        "The management of school operations",
      ],
      correctAnswer: "The theory and practice of teaching",
      explanation:
        "Pedagogy refers to the theory and practice of teaching, including the strategies, methods, and approaches that educators use to facilitate learning in students.",
    },
  },
];
