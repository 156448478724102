import React, { useMemo, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  Avatar,
} from "@mui/material";
import Lottie from "react-lottie";
import correctAnimation from "./animations/correctAnswer.json";
import incorrectAnimation from "./animations/incorrectAnswer.json";

// Fisher-Yates shuffle algorithm
const shuffleArray = (array) => {
  let shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const ParticipantView = ({
  currentQuestion,
  currentQuestionIndex,
  totalQuestions,
  totalParticipants,
  title,
  answer,
  setAnswer,
  handleSubmitAnswer,
  hasSubmitted,
  showAnswers,
  correctAnswer,
  explanation,
  avatar,
}) => {
  // Ensure options exist before attempting to shuffle
  const shuffledOptions = useMemo(() => {
    return currentQuestion && currentQuestion.options
      ? shuffleArray(currentQuestion.options)
      : [];
  }, [currentQuestion]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // For most browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  if (!currentQuestion) {
    return <Typography>Loading....</Typography>;
  }

  return (
    <Box sx={{ width: "100%", maxWidth: "1000px", mx: "auto" }}>
      <Box display="flex" alignItems="center" mb={2}>
        <Avatar
          src={avatar}
          alt="Your Avatar"
          sx={{ width: 56, height: 56, mr: 2 }}
        />
        <Box>
          <Typography variant="h6">{title}</Typography>
        </Box>
      </Box>
      {currentQuestion && (
        <Card elevation={3} sx={{ position: "relative", width: "100%" }}>
          <CardContent>
            {showAnswers && answer === correctAnswer && (
              <Box sx={{ position: "absolute", top: 5, right: 8 }}>
                <Lottie
                  options={{ animationData: correctAnimation }}
                  height={50}
                  width={50}
                />
              </Box>
            )}
            {showAnswers && answer !== correctAnswer && (
              <Box sx={{ position: "absolute", top: 5, right: 28 }}>
                <Lottie
                  options={{ animationData: incorrectAnimation }}
                  height={50}
                  width={50}
                />
              </Box>
            )}
            <Typography variant="h6" gutterBottom>
              Question {currentQuestionIndex + 1} of {totalQuestions}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {currentQuestion.question}
            </Typography>
            <Grid container spacing={2}>
              {shuffledOptions.length > 0 ? (
                shuffledOptions.map((option, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Button
                      variant={answer === option ? "contained" : "outlined"}
                      color={
                        showAnswers
                          ? option === correctAnswer
                            ? "success"
                            : answer === option
                              ? "error"
                              : "primary"
                          : "primary"
                      }
                      onClick={() => setAnswer(option)}
                      fullWidth
                      disabled={hasSubmitted || showAnswers}
                      sx={{
                        borderWidth: 2,
                        borderColor: showAnswers
                          ? option === correctAnswer
                            ? "success.main"
                            : answer === option
                              ? "error.main"
                              : "primary.main"
                          : "primary.main",
                        borderStyle: "solid",
                      }}
                    >
                      {option}
                      {showAnswers && option === correctAnswer && " ✓"}
                    </Button>
                  </Grid>
                ))
              ) : (
                <Typography variant="body1" color="error.main">
                  No options available for this question.
                </Typography>
              )}
            </Grid>
          </CardContent>
          <CardActions>
            {!showAnswers && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmitAnswer}
                disabled={!answer || hasSubmitted}
                fullWidth
              >
                Submit Answer
              </Button>
            )}
          </CardActions>
        </Card>
      )}

      {hasSubmitted && !showAnswers && (
        <Typography variant="body1" color="success.main" sx={{ mt: 2 }}>
          Answer submitted successfully! Waiting for results...
        </Typography>
      )}
      {showAnswers && (
        <Box mt={2}>
          <Typography
            variant="body1"
            color={answer === correctAnswer ? "success.main" : "error.main"}
          >
            {answer === correctAnswer
              ? "Correct!"
              : `Incorrect. The correct answer was: ${correctAnswer}`}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            Explanation: {explanation}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ParticipantView;
