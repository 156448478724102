// Business & Economics - list of subtopics and related MCQs
export const businessTopics = [
  {
    suggestion: "Understand the fundamentals of management",
    sampleMCQ: {
      question: "Which of the following is a primary function of management?",
      options: [
        "Marketing",
        "Organizing",
        "Auditing",
        "Research and Development",
      ],
      correctAnswer: "Organizing",
      explanation:
        "Organizing is one of the primary functions of management, which involves structuring resources and activities to accomplish organizational goals efficiently.",
    },
  },
  {
    suggestion: "Learn about the different types of business ownership",
    sampleMCQ: {
      question:
        "Which of the following is a characteristic of a sole proprietorship?",
      options: [
        "Limited liability",
        "Owned by shareholders",
        "Unlimited liability",
        "Separate legal entity",
      ],
      correctAnswer: "Unlimited liability",
      explanation:
        "A sole proprietorship has unlimited liability, meaning the owner is personally responsible for the business's debts and obligations.",
    },
  },
  {
    suggestion: "Explore the basics of marketing strategies",
    sampleMCQ: {
      question: "Which of the following is part of the marketing mix?",
      options: ["Promotion", "Profit", "Production", "Payroll"],
      correctAnswer: "Promotion",
      explanation:
        "Promotion is one of the four Ps of the marketing mix (Product, Price, Place, Promotion) and refers to activities that communicate the product's value and persuade customers to purchase.",
    },
  },
  {
    suggestion: "Understand the key concepts of financial accounting",
    sampleMCQ: {
      question:
        "What financial statement shows a company's revenues and expenses?",
      options: [
        "Balance sheet",
        "Income statement",
        "Cash flow statement",
        "Statement of retained earnings",
      ],
      correctAnswer: "Income statement",
      explanation:
        "The income statement shows a company's revenues and expenses over a specific period, indicating whether the company made a profit or incurred a loss.",
    },
  },
  {
    suggestion: "Learn about the basics of supply and demand in economics",
    sampleMCQ: {
      question:
        "What happens to the price of a good if demand increases but supply remains constant?",
      options: [
        "The price decreases",
        "The price remains the same",
        "The price increases",
        "The supply decreases",
      ],
      correctAnswer: "The price increases",
      explanation:
        "If demand increases but supply remains constant, the price typically increases because more people are competing for the same amount of goods.",
    },
  },
  {
    suggestion: "Explore the role of entrepreneurship in business",
    sampleMCQ: {
      question: "Which of the following best describes an entrepreneur?",
      options: [
        "A person who works for a large company",
        "An individual who starts and runs their own business",
        "A manager responsible for day-to-day operations",
        "A financial advisor",
      ],
      correctAnswer: "An individual who starts and runs their own business",
      explanation:
        "An entrepreneur is someone who identifies a business opportunity, starts their own business, and assumes the risks and rewards of the venture.",
    },
  },
  {
    suggestion: "Understand the basics of business ethics",
    sampleMCQ: {
      question: "Which of the following is a key principle of business ethics?",
      options: [
        "Profit maximization",
        "Corporate social responsibility",
        "Market expansion",
        "Operational efficiency",
      ],
      correctAnswer: "Corporate social responsibility",
      explanation:
        "Corporate social responsibility (CSR) is a key principle of business ethics, where businesses consider their impact on society and the environment in addition to profit-making.",
    },
  },
  {
    suggestion: "Learn about the role of leadership in organizations",
    sampleMCQ: {
      question:
        "Which leadership style involves making decisions without consulting subordinates?",
      options: [
        "Autocratic",
        "Democratic",
        "Laissez-faire",
        "Transformational",
      ],
      correctAnswer: "Autocratic",
      explanation:
        "In an autocratic leadership style, the leader makes decisions independently without input from subordinates, often leading to fast decision-making but less employee involvement.",
    },
  },
  {
    suggestion: "Explore the importance of human resource management (HRM)",
    sampleMCQ: {
      question:
        "Which of the following is a function of human resource management?",
      options: [
        "Product development",
        "Recruitment and selection",
        "Market research",
        "Financial auditing",
      ],
      correctAnswer: "Recruitment and selection",
      explanation:
        "Recruitment and selection is a key function of HRM, where the department is responsible for attracting, selecting, and hiring the right candidates for the organization.",
    },
  },
  {
    suggestion: "Understand the principles of strategic management",
    sampleMCQ: {
      question:
        "Which of the following is the first step in the strategic management process?",
      options: [
        "Strategy formulation",
        "Environmental scanning",
        "Strategy implementation",
        "Strategy evaluation",
      ],
      correctAnswer: "Environmental scanning",
      explanation:
        "Environmental scanning is the first step in the strategic management process, involving analyzing internal and external environments to identify opportunities and threats.",
    },
  },
  {
    suggestion: "Learn about investment options and financial markets",
    sampleMCQ: {
      question:
        "Which financial market involves the buying and selling of company shares?",
      options: [
        "Bond market",
        "Stock market",
        "Commodities market",
        "Currency market",
      ],
      correctAnswer: "Stock market",
      explanation:
        "The stock market is where investors buy and sell shares of publicly traded companies, representing ownership in the company.",
    },
  },
  {
    suggestion: "Explore international business and global markets",
    sampleMCQ: {
      question:
        "Which of the following is a potential benefit of international trade?",
      options: [
        "Higher taxes",
        "Restricted market access",
        "Access to new markets",
        "Increased regulatory hurdles",
      ],
      correctAnswer: "Access to new markets",
      explanation:
        "International trade allows businesses to access new markets, leading to increased sales opportunities, economies of scale, and diversification of risk.",
    },
  },
  {
    suggestion: "Understand the basics of corporate finance",
    sampleMCQ: {
      question:
        "Which of the following is a common way for companies to raise capital?",
      options: [
        "Selling products",
        "Issuing bonds",
        "Hiring employees",
        "Buying assets",
      ],
      correctAnswer: "Issuing bonds",
      explanation:
        "Companies often raise capital by issuing bonds, which are debt instruments that investors purchase, providing the company with funds in exchange for interest payments.",
    },
  },
  {
    suggestion:
      "Learn about the importance of customer relationship management (CRM)",
    sampleMCQ: {
      question:
        "Which of the following is a primary goal of customer relationship management (CRM)?",
      options: [
        "Increase production efficiency",
        "Improve customer retention",
        "Reduce labor costs",
        "Enhance product design",
      ],
      correctAnswer: "Improve customer retention",
      explanation:
        "The primary goal of CRM is to improve customer retention by managing relationships with current and potential customers to increase loyalty and long-term profitability.",
    },
  },
  {
    suggestion: "Explore the significance of business law and regulations",
    sampleMCQ: {
      question: "Which law protects consumers from false advertising?",
      options: [
        "Contract law",
        "Antitrust law",
        "Consumer protection law",
        "Labor law",
      ],
      correctAnswer: "Consumer protection law",
      explanation:
        "Consumer protection laws are designed to prevent businesses from engaging in false advertising, unfair practices, and fraud, protecting consumers' rights in the marketplace.",
    },
  },
];
