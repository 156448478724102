import React, { useCallback, useEffect, useState } from "react";
import {
  Drawer as MuiDrawer,
  Box,
  TextField,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const Drawer = ({
  socket,
  drawerOpen,
  toggleDrawer,
  searchQuery,
  filteredQuizzes,
  loadedQuizId,
  quizId,
  setLoadedQuizId,
  setSearchQuery,
  setFilteredQuizzes,
  quizzesList,
  setQuizId,
  setTitle,
  setMode,
  setEditableQuestions,
  setQuizzesList,
  participantId,
  setSelectedSession,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [pastSessions, setPastSessions] = useState([]);
  const [loadingSession, setLoadingSession] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    if (newValue === 1 && participantId) {
      socket.emit("getSessionsByHost", { participantId });
    }
  };

  const requestLoadQuiz = useCallback(
    (quizId) => {
      socket.emit("loadQuiz", { quizId });
      setLoadedQuizId(quizId);
    },
    [socket, setLoadedQuizId],
  );

  const handleDeleteQuiz = useCallback(
    (quizId) => {
      if (window.confirm("Are you sure you want to delete this quiz?")) {
        socket.emit("deleteQuiz", { quizId });
      }
    },
    [socket],
  );

  const handleSearchQueryChange = useCallback(
    (event) => {
      const query = event.target.value;
      setSearchQuery(query);
      if (query.trim() === "") {
        setFilteredQuizzes(quizzesList);
      } else {
        const filtered = quizzesList.filter((quiz) =>
          quiz.title.toLowerCase().includes(query.toLowerCase()),
        );
        setFilteredQuizzes(filtered);
      }
    },
    [quizzesList, setSearchQuery, setFilteredQuizzes],
  );

  const handleSessionClick = (sessionId) => {
    setLoadingSession(true);
    socket.emit("getSessionDetails", { sessionId });
  };

  useEffect(() => {
    socket.on("savedQuizzesList", (quizzes) => {
      setQuizzesList(quizzes);
      setFilteredQuizzes(quizzes);
    });

    return () => {
      socket.off("savedQuizzesList");
    };
  }, [socket, setQuizzesList, setFilteredQuizzes]);

  useEffect(() => {
    socket.on("quizDeleteSuccess", ({ quizId }) => {
      setQuizzesList((prev) => prev.filter((quiz) => quiz.id !== quizId));
      setFilteredQuizzes((prev) => prev.filter((quiz) => quiz.id !== quizId));

      if (loadedQuizId === quizId) {
        setLoadedQuizId("");
        setQuizId("");
        setMode("choose");
      }
    });

    return () => {
      socket.off("quizDeleteSuccess");
    };
  }, [
    socket,
    setQuizzesList,
    setFilteredQuizzes,
    loadedQuizId,
    setLoadedQuizId,
    setQuizId,
    setMode,
  ]);

  useEffect(() => {
    socket.on("quizLoadSuccess", (quiz) => {
      const loadedQuestions = quiz.content.map((item) => ({
        contentId: item.contentId,
        quizId: item.quizId,
        type: item.type,
        order: item.order,
        ...item.data,
      }));

      setEditableQuestions(loadedQuestions);
      setTitle(quiz.title);
      setQuizId(quiz.quizId);
      setMode("choose");
    });
    return () => {
      socket.off("quizLoadSuccess");
    };
  }, [socket, setEditableQuestions, setTitle, setQuizId, setMode]);

  useEffect(() => {
    if (participantId) {
      socket.emit("getSessionsByHost", { participantId });
    }

    socket.on("sessionsByHostSuccess", (sessions) => {
      setPastSessions(sessions);
    });

    return () => {
      socket.off("sessionsByHostSuccess");
    };
  }, [socket, participantId]);

  useEffect(() => {
    socket.on("sessionDetailsSuccess", (sessionDetails) => {
      setLoadingSession(false);
      setSelectedSession(sessionDetails);
      setMode("sessionDetails");
    });

    return () => {
      socket.off("sessionDetailsSuccess");
    };
  }, [socket, setSelectedSession, setMode]);

  const formatToLocalTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <MuiDrawer
      anchor="left"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      PaperProps={{
        sx: {
          width: 300,
          backgroundColor: "background.default",
          color: "text.primary",
        },
      }}
    >
      <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth">
        <Tab label="Quizzes" />
        <Tab label="Past Sessions" />
      </Tabs>

      <Box sx={{ padding: 2 }}>
        {selectedTab === 0 && (
          <>
            <TextField
              label="Search Quizzes"
              value={searchQuery}
              onChange={handleSearchQueryChange}
              fullWidth
            />
            <Divider sx={{ my: 2 }} />
            <List>
              {filteredQuizzes.length === 0 ? (
                <ListItem>
                  <ListItemText
                    primary="No quizzes found.."
                    sx={{ textAlign: "center", fontWeight: "bold" }}
                  />
                </ListItem>
              ) : (
                filteredQuizzes
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at),
                  ) // Sort quizzes by time
                  .map((quiz) => (
                    <ListItem
                      button
                      key={quiz.id}
                      onClick={() => requestLoadQuiz(quiz.id)}
                      sx={{
                        backgroundColor:
                          quiz.id === (loadedQuizId || quizId)
                            ? "primary.main"
                            : "inherit",
                        color:
                          quiz.id === (loadedQuizId || quizId)
                            ? "white"
                            : "inherit", // Set text to white on selected
                      }}
                    >
                      <ListItemText
                        primary={quiz.title}
                        secondary={
                          <Typography
                            variant="body2"
                            component="span"
                            style={{
                              fontSize: "0.8em",
                              color:
                                quiz.id === (loadedQuizId || quizId)
                                  ? "white"
                                  : "grey",
                            }} // Change text color for selected item
                          >
                            {`${formatToLocalTime(quiz.created_at)}`}
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeleteQuiz(quiz.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
              )}
            </List>
          </>
        )}
        {selectedTab === 1 && (
          <List>
            {pastSessions.length === 0 ? (
              <ListItem>
                <ListItemText
                  primary="No past sessions found.."
                  sx={{ textAlign: "center", fontWeight: "bold" }}
                />
              </ListItem>
            ) : (
              pastSessions
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort sessions by time
                .map((session) => (
                  <ListItem
                    button
                    key={session.sessionId}
                    onClick={() => handleSessionClick(session.sessionId)}
                    sx={{
                      backgroundColor:
                        session.sessionId === loadedQuizId
                          ? "primary.main"
                          : "inherit",
                      color:
                        session.sessionId === loadedQuizId
                          ? "white"
                          : "inherit", // Set text to white on selected
                    }}
                  >
                    <ListItemText
                      primary={session.quizTitle || "Untitled Session"}
                      secondary={
                        <Typography
                          variant="body2"
                          component="span"
                          style={{
                            fontSize: "0.8em",
                            color:
                              session.sessionId === loadedQuizId
                                ? "white"
                                : "grey",
                          }} // Change text color for selected item
                        >
                          {`Hosted At: ${formatToLocalTime(session.created_at)}`}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))
            )}
          </List>
        )}
      </Box>
    </MuiDrawer>
  );
};

export default Drawer;
