// Political Science - list of subtopics and related MCQs
export const politicalScienceTopics = [
  {
    suggestion: "Understand the basics of political ideologies",
    sampleMCQ: {
      question:
        "Which political ideology emphasizes limited government and individual liberties?",
      options: ["Socialism", "Liberalism", "Communism", "Authoritarianism"],
      correctAnswer: "Liberalism",
      explanation:
        "Liberalism is a political ideology that emphasizes individual freedoms, civil rights, democracy, and limited government intervention in personal lives and the economy.",
    },
  },
  {
    suggestion: "Learn about the different forms of government",
    sampleMCQ: {
      question:
        "Which form of government is characterized by rule by the people, either directly or through representatives?",
      options: ["Monarchy", "Oligarchy", "Democracy", "Theocracy"],
      correctAnswer: "Democracy",
      explanation:
        "Democracy is a system of government in which power is vested in the people, who exercise that power directly or through elected representatives.",
    },
  },
  {
    suggestion: "Explore the concept of separation of powers",
    sampleMCQ: {
      question:
        "Which of the following refers to the division of government responsibilities into distinct branches?",
      options: [
        "Federalism",
        "Separation of powers",
        "Constitutionalism",
        "Checks and balances",
      ],
      correctAnswer: "Separation of powers",
      explanation:
        "Separation of powers is a model of governance in which the legislative, executive, and judicial branches are kept separate to prevent the concentration of power and provide checks and balances.",
    },
  },
  {
    suggestion: "Understand the principles of federalism",
    sampleMCQ: {
      question:
        "Which system of government divides power between a central government and regional governments?",
      options: ["Unitary system", "Confederation", "Federalism", "Monarchy"],
      correctAnswer: "Federalism",
      explanation:
        "Federalism is a system of government in which power is divided between a central authority and constituent political units, such as states or provinces, allowing each to govern within its own jurisdiction.",
    },
  },
  {
    suggestion: "Learn about the role of political parties in democracies",
    sampleMCQ: {
      question:
        "What is the primary function of political parties in a democracy?",
      options: [
        "To regulate the economy",
        "To educate citizens on legal matters",
        "To nominate candidates for public office and promote political agendas",
        "To create international trade agreements",
      ],
      correctAnswer:
        "To nominate candidates for public office and promote political agendas",
      explanation:
        "Political parties play a key role in democracies by nominating candidates for public office, promoting political platforms, and helping organize elections and governance.",
    },
  },
  {
    suggestion: "Explore the basics of electoral systems",
    sampleMCQ: {
      question:
        "Which electoral system is based on the principle of 'one person, one vote'?",
      options: [
        "Proportional representation",
        "First-past-the-post",
        "Electoral college",
        "Indirect voting",
      ],
      correctAnswer: "First-past-the-post",
      explanation:
        "First-past-the-post is a simple plurality voting system where the candidate with the most votes in a single-member district wins the election, based on the principle of 'one person, one vote.'",
    },
  },
  {
    suggestion: "Understand the concept of political socialization",
    sampleMCQ: {
      question:
        "Which of the following is a primary agent of political socialization?",
      options: [
        "The military",
        "The family",
        "International organizations",
        "Corporations",
      ],
      correctAnswer: "The family",
      explanation:
        "The family is a primary agent of political socialization, shaping individuals' political beliefs and values from an early age, alongside schools, peers, media, and religious institutions.",
    },
  },
  {
    suggestion: "Learn about the theory of international relations",
    sampleMCQ: {
      question:
        "Which theory in international relations emphasizes power and national interest as key drivers of state behavior?",
      options: ["Liberalism", "Realism", "Constructivism", "Marxism"],
      correctAnswer: "Realism",
      explanation:
        "Realism is an international relations theory that emphasizes the role of power, national interest, and the anarchic nature of the international system in shaping state behavior.",
    },
  },
  {
    suggestion: "Explore the impact of globalization on political systems",
    sampleMCQ: {
      question:
        "Which of the following is a common effect of globalization on national sovereignty?",
      options: [
        "Increased isolationism",
        "Strengthening of national borders",
        "Erosion of national sovereignty due to global governance institutions",
        "Decreased international cooperation",
      ],
      correctAnswer:
        "Erosion of national sovereignty due to global governance institutions",
      explanation:
        "Globalization often leads to the erosion of national sovereignty, as global institutions like the United Nations and World Trade Organization influence national policies and decision-making.",
    },
  },
  {
    suggestion: "Understand the basics of political philosophy",
    sampleMCQ: {
      question:
        "Which philosopher is known for his work on the 'social contract' theory?",
      options: [
        "Aristotle",
        "Karl Marx",
        "Jean-Jacques Rousseau",
        "John Locke",
      ],
      correctAnswer: "Jean-Jacques Rousseau",
      explanation:
        "Jean-Jacques Rousseau is famous for his 'social contract' theory, which argues that people give up some individual freedoms in exchange for security and the protection of rights within a governed society.",
    },
  },
  {
    suggestion: "Learn about human rights and international law",
    sampleMCQ: {
      question:
        "Which document serves as a foundation for international human rights law?",
      options: [
        "The Magna Carta",
        "The Universal Declaration of Human Rights",
        "The U.S. Constitution",
        "The Treaty of Versailles",
      ],
      correctAnswer: "The Universal Declaration of Human Rights",
      explanation:
        "The Universal Declaration of Human Rights, adopted by the United Nations in 1948, serves as a foundation for international human rights law, outlining fundamental rights that all people are entitled to.",
    },
  },
  {
    suggestion: "Explore the concept of checks and balances",
    sampleMCQ: {
      question:
        "What is the purpose of checks and balances in a government system?",
      options: [
        "To limit the power of each branch of government",
        "To give all power to the executive branch",
        "To reduce the influence of the judiciary",
        "To centralize control in the hands of one leader",
      ],
      correctAnswer: "To limit the power of each branch of government",
      explanation:
        "Checks and balances are designed to prevent any one branch of government (executive, legislative, or judicial) from becoming too powerful by allowing each branch to limit the powers of the others.",
    },
  },
  {
    suggestion: "Understand the role of public opinion in politics",
    sampleMCQ: {
      question:
        "Which of the following is a key way public opinion influences government policy?",
      options: [
        "Through public demonstrations",
        "Through elections and voting",
        "Through private organizations",
        "Through international treaties",
      ],
      correctAnswer: "Through elections and voting",
      explanation:
        "Public opinion influences government policy primarily through elections and voting, where citizens express their preferences for policies and leaders, shaping political decisions.",
    },
  },
  {
    suggestion: "Learn about the role of civil society in governance",
    sampleMCQ: {
      question:
        "Which of the following is an example of a civil society organization?",
      options: [
        "The national military",
        "A non-governmental organization (NGO)",
        "The federal government",
        "A private corporation",
      ],
      correctAnswer: "A non-governmental organization (NGO)",
      explanation:
        "Civil society organizations, such as NGOs, operate independently from the government and play a crucial role in advocating for social change, providing services, and influencing policy.",
    },
  },
  {
    suggestion: "Explore the impact of political revolutions",
    sampleMCQ: {
      question:
        "Which political revolution is known for overthrowing the monarchy and establishing a republic in 1789?",
      options: [
        "The American Revolution",
        "The Russian Revolution",
        "The French Revolution",
        "The Chinese Revolution",
      ],
      correctAnswer: "The French Revolution",
      explanation:
        "The French Revolution in 1789 led to the overthrow of the French monarchy and the establishment of a republic, marking a major turning point in world history with its emphasis on liberty, equality, and fraternity.",
    },
  },
];
