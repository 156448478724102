import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
  Card,
  CardContent,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel,
  List,
  Skeleton,
  Chip,
  Drawer,
  useMediaQuery,
} from "@mui/material";
import {
  PlayArrow as PlayArrowIcon,
  Check as CheckIcon,
  Search as SearchIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  Shuffle as ShuffleIcon,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import correctAnimation from "./animations/loading.json"; // Ensure the path is correct
import QuizSyncText from "./QuizSyncText";
import topics from "./Topics.js";

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const Create = ({
  socket,
  isProcessing,
  setIsProcessing,
  prompts,
  setPrompts,
  participantId,
  additionalPrompt = "",
  setAdditionalPrompt,
  isAddingQuestion,
  setIsAddingQuestion,
  editableQuestions = [],
  setEditableQuestions,
  quizId = "",
  setQuizId,
  title = "",
  setTitle,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [editMode, setEditMode] = useState(true); // Render mode is default
  const [isGeneratingTitle, setIsGeneratingTitle] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [isStartingSession, setIsStartingSession] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State for drawer

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleDeeperPromptGeneration = async (suggestion) => {
    setPrompts([]);
    setIsProcessing(true);
    try {
      socket.emit("generatePrompts", { topic: suggestion });
    } catch (error) {
      console.error("Error occurred while generating prompts:", error);
      setIsProcessing(false);
    }
  };

  // Save Quiz Function
  const saveQuiz = useCallback(() => {
    if (!quizId) {
      console.error("Quiz ID is not set. Cannot save quiz.");
      return;
    }
    const formattedQuestions = editableQuestions.map((question, index) => ({
      order: index,
      contentId: question.contentId,
      type: question.type,
      data: {
        question: question.question,
        options: question.options,
        correctAnswer: question.correctAnswer,
        explanation: question.explanation,
      },
    }));
    socket.emit("saveQuiz", {
      quizId: quizId,
      questions: formattedQuestions,
      title: title,
      topic: searchQuery,
      participantId: participantId,
    });
  }, [socket, quizId, editableQuestions, title, searchQuery, participantId]);

  // Listener for quizIdGenerated
  useEffect(() => {
    const handleQuizIdGenerated = (data) => {
      setQuizId(data.quizId);
      console.log("Quiz ID received:", data.quizId);
      saveQuiz(); // Save the quiz now that we have the quizId
    };

    socket.on("quizIdGenerated", handleQuizIdGenerated);

    return () => {
      socket.off("quizIdGenerated", handleQuizIdGenerated);
    };
  }, [socket, saveQuiz, setQuizId]);

  // Triggering Quiz ID Generation and Save Quiz
  useEffect(() => {
    if (editableQuestions.length > 0) {
      if (!quizId) {
        socket.emit("generateQuizId");
      } else {
        saveQuiz();
      }
    }
  }, [socket, editableQuestions, quizId, saveQuiz]);

  // Additional single question prompt
  const requestAddQuestion = useCallback(() => {
    setIsAddingQuestion(true);
    socket.emit("generateSingleQuestion", {
      quizId,
      currentQuestions: editableQuestions,
      additionalPrompt,
    });
  }, [
    socket,
    setIsAddingQuestion,
    quizId,
    editableQuestions,
    additionalPrompt,
  ]);

  const handleAddNewQuestion = () => {
    if (additionalPrompt && additionalPrompt.trim()) {
      requestAddQuestion();
      setAdditionalPrompt("");
    }
  };

  useEffect(() => {
    socket.on(
      "singleQuestionGenerationSuccess",
      ({ quizId: receivedQuizId, question, contentId, type }) => {
        setEditableQuestions((prevQuestions) => [
          ...prevQuestions,
          { ...question, contentId, type },
        ]);
        setIsAddingQuestion(false);
        if (!quizId) {
          setQuizId(receivedQuizId);
        }
      },
    );
    return () => {
      socket.off("singleQuestionGenerationSuccess");
    };
  }, [socket, quizId, setEditableQuestions, setIsAddingQuestion, setQuizId]);

  // Function to request a title from the server
  const handleGenerateTitle = useCallback(() => {
    setIsGeneratingTitle(true);
    socket.emit("generateTitle", { content: editableQuestions });
  }, [socket, editableQuestions]);

  // Auto-generate title after 1 question
  useEffect(() => {
    if (editableQuestions.length === 1 && !title) {
      handleGenerateTitle();
    }
  }, [editableQuestions.length, title, handleGenerateTitle]);

  // Listen for title generation response
  useEffect(() => {
    socket.on("titleGeneratedSuccess", ({ title }) => {
      setTitle(title);
      setIsGeneratingTitle(false);
    });

    socket.on("socketError", (message) => {
      console.error("Socket error:", message);
      setIsGeneratingTitle(false);
    });

    // Cleanup on component unmount
    return () => {
      socket.off("titleGeneratedSuccess");
      socket.off("socketError");
    };
  }, [socket, setTitle, setIsGeneratingTitle]);

  const handleCreateQuizSession = useCallback(() => {
    if (!participantId) return;
    setIsStartingSession(true); // Show full-screen loading animation
    socket.emit("createQuizSession", {
      quizId,
      participantId,
      questions: editableQuestions,
      title,
      topic: searchQuery,
    });
  }, [socket, quizId, participantId, editableQuestions, title, searchQuery]);

  const requestGeneratePrompt = async (searchQuery) => {
    setPrompts([]);
    setIsProcessing(true);
    try {
      socket.emit("generatePrompts", { topic: searchQuery });
    } catch (error) {
      console.error("Error occurred while generating prompts:", error);
      setIsProcessing(false);
    }
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleAnswerSelect = (questionIndex, selectedOption) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionIndex]: selectedOption,
    }));
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: correctAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleAddToQuiz = (suggestion) => {
    const newQuestion = {
      type: "mcq",
      question: suggestion.sampleMCQ.question,
      options: suggestion.sampleMCQ.options,
      correctAnswer: suggestion.sampleMCQ.correctAnswer,
      explanation: suggestion.sampleMCQ.explanation,
    };
    setEditableQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
  };

  // Editable Questions Handlers
  const handleQuestionChange = useCallback(
    (index, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[index].question = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleOptionChange = useCallback(
    (qIndex, oIndex, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[qIndex].options[oIndex] = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleCorrectAnswerChange = useCallback(
    (index, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[index].correctAnswer = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleExplanationChange = useCallback(
    (index, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[index].explanation = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleDeleteQuestion = useCallback(
    (index) => {
      const newQuestions = editableQuestions.filter(
        (_, qIndex) => qIndex !== index,
      );
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleModeSwitch = () => {
    setEditMode(!editMode);
  };

  const moveArrayItem = (arr, fromIndex, toIndex) => {
    if (toIndex >= 0 && toIndex < arr.length) {
      const newArr = [...arr];
      const [movedItem] = newArr.splice(fromIndex, 1);
      newArr.splice(toIndex, 0, movedItem);
      return newArr;
    }
    return arr;
  };

  const handleMoveQuestionUp = (contentId) => {
    const index = editableQuestions.findIndex((q) => q.contentId === contentId);
    if (index > 0) {
      setEditableQuestions((prevQuestions) =>
        moveArrayItem(prevQuestions, index, index - 1),
      );
    }
  };

  const handleMoveQuestionDown = (contentId) => {
    const index = editableQuestions.findIndex((q) => q.contentId === contentId);
    if (index < editableQuestions.length - 1) {
      setEditableQuestions((prevQuestions) =>
        moveArrayItem(prevQuestions, index, index + 1),
      );
    }
  };

  useEffect(() => {
    const handlePromptsGeneratedSuccess = (data) => {
      setPrompts(data.prompts); // Update prompts with the new data
      setIsProcessing(false); // Stop the loading animation
    };

    socket.on("promptsGeneratedSuccess", handlePromptsGeneratedSuccess);

    // Cleanup the listener when the component unmounts
    return () => {
      socket.off("promptsGeneratedSuccess", handlePromptsGeneratedSuccess);
    };
  }, [socket, setIsProcessing, setPrompts]);

  useEffect(() => {
    setPrompts(topics);
  }, [setPrompts]);

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  if (isStartingSession) {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Lottie options={defaultOptions} height={200} width={200} />
      </Box>
    );
  }

  return (
    <MathJaxContext>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh",
          overflow: "hidden",
          px: 2,
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {/* Left Side - Main Content */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            overflowY: "auto", // To allow scrolling if needed
            zIndex: 1, // Ensure main content is above the drawer in mobile view
          }}
        >
          {/* Heading */}
          {editableQuestions.length === 0 && (
            <Typography variant="h4" gutterBottom>
              Create a <QuizSyncText>QuizSync</QuizSyncText>
            </Typography>
          )}

          <Box sx={{ width: "100%", maxWidth: "800px", mt: 2 }}>
            {/* Mode Switch, Start Session, and Quiz ID */}
            {editableQuestions.length > 0 && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={editMode}
                      onChange={handleModeSwitch}
                      color="primary"
                    />
                  }
                  label={editMode ? "Edit Mode" : "Render Mode"}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateQuizSession}
                  startIcon={<PlayArrowIcon />}
                >
                  Start Session
                </Button>
              </Box>
            )}

            {/* Title */}
            {editableQuestions.length > 0 && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                {editMode ? (
                  <TextField
                    label="Quiz Title"
                    value={title}
                    onChange={handleTitleChange}
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={handleGenerateTitle}
                          disabled={isGeneratingTitle}
                        >
                          {isGeneratingTitle ? (
                            <CircularProgress size={24} />
                          ) : (
                            <ShuffleIcon />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                ) : (
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      sx={{ textAlign: "center", mr: 1 }}
                    >
                      {title}
                    </Typography>
                    <IconButton
                      onClick={handleGenerateTitle}
                      disabled={isGeneratingTitle}
                    >
                      {isGeneratingTitle ? (
                        <CircularProgress size={24} />
                      ) : (
                        <ShuffleIcon />
                      )}
                    </IconButton>
                  </Box>
                )}
              </Box>
            )}

            {/* Editable Questions List */}
            {editMode ? (
              <List sx={{ width: "100%" }}>
                {editableQuestions.map((question, qIndex) => (
                  <Accordion
                    key={`question-${qIndex}`}
                    sx={{
                      marginBottom: 2,
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`question-${qIndex}-content`}
                      id={`question-${qIndex}-header`}
                    >
                      <Box display="flex" flexDirection="column" width="100%">
                        <Typography
                          variant="h6"
                          mb={1}
                        >{`Q${qIndex + 1}: ${question.question}`}</Typography>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box>
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                handleDeleteQuestion(qIndex);
                              }}
                              size="small"
                              color="error"
                            >
                              <DeleteIcon />
                            </IconButton>
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                handleMoveQuestionUp(question.contentId);
                              }}
                              size="small"
                              disabled={qIndex === 0}
                            >
                              <ArrowUpwardIcon />
                            </IconButton>
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                handleMoveQuestionDown(question.contentId);
                              }}
                              size="small"
                              disabled={qIndex === editableQuestions.length - 1}
                            >
                              <ArrowDownwardIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CardContent>
                        {/* Editable mode */}
                        <TextField
                          label={`Question ${qIndex + 1}`}
                          value={question.question}
                          onChange={(event) =>
                            handleQuestionChange(qIndex, event)
                          }
                          fullWidth
                          margin="normal"
                        />
                        {question.options.map((option, oIndex) => (
                          <TextField
                            key={`question-${qIndex}-option-${oIndex}`}
                            label={`Option ${oIndex + 1}`}
                            value={option}
                            onChange={(event) =>
                              handleOptionChange(qIndex, oIndex, event)
                            }
                            fullWidth
                            margin="normal"
                          />
                        ))}
                        <TextField
                          label="Correct Answer"
                          value={question.correctAnswer}
                          onChange={(event) =>
                            handleCorrectAnswerChange(qIndex, event)
                          }
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Explanation"
                          value={question.explanation}
                          onChange={(event) =>
                            handleExplanationChange(qIndex, event)
                          }
                          fullWidth
                          margin="normal"
                        />
                      </CardContent>
                    </AccordionDetails>
                  </Accordion>
                ))}
                {isAddingQuestion && (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={80}
                    sx={{ marginBottom: 2 }}
                  />
                )}
              </List>
            ) : (
              // Render Mode
              <List sx={{ width: "100%" }}>
                {editableQuestions.map((question, qIndex) => {
                  const selectedAnswer = selectedAnswers[qIndex];
                  const isCorrect = selectedAnswer === question.correctAnswer;

                  return (
                    <motion.div
                      key={`render-question-${qIndex}`}
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{
                        duration: 0.5,
                        delay: qIndex * 0.1,
                      }}
                    >
                      <Card
                        variant="outlined"
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: 2,
                          gap: 2, // Added gap between elements
                          "&:hover": {
                            boxShadow: 6,
                            transform: "scale(1.02)",
                            transition: "transform 0.2s ease-in-out",
                          },
                        }}
                      >
                        <CardContent
                          sx={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2, // Added gap between elements
                          }}
                        >
                          <Box sx={{ mb: 2 }}>
                            <Typography
                              variant="h6"
                              sx={{ textAlign: "center", marginBottom: 2 }}
                            >
                              {`Q${qIndex + 1}: ${question.question}`}
                            </Typography>

                            {/* Render the options */}
                            {question.options.map((option, oIndex) => {
                              const isSelected = selectedAnswer === option;
                              const showCorrect =
                                selectedAnswer &&
                                option === question.correctAnswer;
                              const showIncorrect = isSelected && !isCorrect;

                              return (
                                <Typography
                                  key={`render-option-${qIndex}-${oIndex}`}
                                  sx={{
                                    border: 2,
                                    borderColor: showCorrect
                                      ? "success.main"
                                      : showIncorrect
                                        ? "error.main"
                                        : "inherit",
                                    color: showCorrect
                                      ? "success.main"
                                      : showIncorrect
                                        ? "error.main"
                                        : "inherit",
                                    borderWidth: 2,
                                    padding: "8px 16px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    borderRadius: 1,
                                    transition: "background-color 0.2s ease",
                                    "&:hover": {
                                      backgroundColor: showCorrect
                                        ? "success.light"
                                        : showIncorrect
                                          ? "error.light"
                                          : "action.hover",
                                    },
                                    mb: 1, // Added margin between buttons
                                  }}
                                  onClick={() =>
                                    handleAnswerSelect(qIndex, option)
                                  }
                                >
                                  {option}
                                  {showCorrect && <CheckIcon />}
                                </Typography>
                              );
                            })}
                          </Box>

                          {/* Show explanation and correct answer */}
                          {selectedAnswer && (
                            <Box>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{ mt: 1 }}
                              >
                                Correct Answer:{" "}
                                <strong>{question.correctAnswer}</strong>
                              </Typography>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{ mt: 1 }}
                              >
                                Explanation:{" "}
                                <strong>{question.explanation}</strong>
                              </Typography>
                            </Box>
                          )}
                        </CardContent>
                      </Card>
                    </motion.div>
                  );
                })}
              </List>
            )}

            {/* Add a New Question Card */}
            {/* Now visible in both modes */}
            <Card sx={{ marginBottom: 2, width: "100%" }}>
              <CardContent>
                <Typography variant="h6">Generate MCQ</Typography>
                <TextField
                  label="Enter Prompt Here"
                  value={additionalPrompt}
                  onChange={(event) => setAdditionalPrompt(event.target.value)}
                  fullWidth
                  margin="normal"
                />
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddNewQuestion}
                  disabled={!additionalPrompt.trim()}
                >
                  Add Question
                </Button>
              </CardContent>
            </Card>
          </Box>
        </Box>

        {/* Right Side - Drawer with Icon on right edge */}
        {!isMobile && (
          <>
            <Box
              sx={{
                position: "fixed",
                top: "50%",
                right: 0,
                transform: "translateY(-50%)",
                zIndex: 1000,
              }}
            >
              <DragIndicatorIcon onClick={toggleDrawer(true)} />
            </Box>
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
              PaperProps={{
                sx: {
                  width: "350px",
                },
              }}
            >
              <Box sx={{ padding: 2, mt: 1, width: "100%" }}>
                {/* Search Bar in the right drawer */}
                <Box
                  sx={{
                    width: "100%",
                    mb: 2,
                  }}
                >
                  <TextField
                    label="Enter Topic"
                    margin="normal"
                    size="large"
                    fullWidth
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => requestGeneratePrompt(searchQuery)}
                          disabled={isProcessing}
                        >
                          {isProcessing ? (
                            <CircularProgress size={24} />
                          ) : (
                            <PlayArrowIcon />
                          )}
                        </IconButton>
                      ),
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" && !isProcessing) {
                        requestGeneratePrompt(searchQuery);
                      }
                    }}
                    autoComplete="off"
                  />
                </Box>

                {/* Chips for Topics */}
                <Box sx={{ width: "100%", mb: 2 }}>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {prompts.map((prompt, index) => (
                      <Chip
                        key={index}
                        label={prompt.topic}
                        onClick={() =>
                          setSelectedTopic(
                            selectedTopic === prompt.topic
                              ? null
                              : prompt.topic,
                          )
                        }
                        color={
                          selectedTopic === prompt.topic ? "primary" : "default"
                        }
                        variant={
                          selectedTopic === prompt.topic ? "filled" : "outlined"
                        }
                      />
                    ))}
                  </Box>
                </Box>

                {isProcessing ? (
                  <Lottie options={defaultOptions} height={200} width={200} />
                ) : (
                  prompts.length > 0 && (
                    <>
                      {prompts
                        .filter(
                          (prompt) =>
                            !selectedTopic || prompt.topic === selectedTopic,
                        )
                        .map((prompt, index) => (
                          <Box key={index} sx={{ mb: 4, width: "100%" }}>
                            <Typography variant="h7" sx={{ mb: 2 }}>
                              {prompt.topic}
                            </Typography>
                            <Divider sx={{ mb: 2 }} />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                              }}
                            >
                              {/* Subtopic Cards */}
                              {prompt.suggestions.map(
                                (suggestion, suggestionIndex) => {
                                  const selectedAnswer =
                                    selectedAnswers[
                                      `${index}-${suggestionIndex}`
                                    ];
                                  const isCorrect =
                                    selectedAnswer ===
                                    suggestion.sampleMCQ.correctAnswer;

                                  return (
                                    <motion.div
                                      key={`${index}-${suggestionIndex}`}
                                      initial={{ opacity: 0, y: 50 }}
                                      animate={{ opacity: 1, y: 0 }}
                                      transition={{
                                        duration: 0.5,
                                        delay: suggestionIndex * 0.1,
                                      }}
                                    >
                                      <Card
                                        variant="outlined"
                                        sx={{
                                          width: "100%",
                                          display: "flex",
                                          flexDirection: "column",
                                          "&:hover": {
                                            boxShadow: 6,
                                            transform: "scale(1.02)",
                                            transformOrigin: "center center",
                                            transition:
                                              "transform 0.2s ease-in-out",
                                          },
                                        }}
                                      >
                                        <CardContent
                                          sx={{
                                            flexGrow: 1,
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              mb: 2,
                                            }}
                                          >
                                            <Typography
                                              variant="h6"
                                              sx={{
                                                flexGrow: 1,
                                                textAlign: "center",
                                              }}
                                            >
                                              <MathJax dynamic>
                                                {suggestion.suggestion}
                                              </MathJax>
                                            </Typography>
                                            <IconButton
                                              onClick={() =>
                                                handleDeeperPromptGeneration(
                                                  `${prompt.topic} - ${suggestion.suggestion}`,
                                                )
                                              }
                                              size="small"
                                            >
                                              <SearchIcon />
                                            </IconButton>
                                          </Box>

                                          {suggestion.sampleMCQ && (
                                            <Box sx={{ mb: 2, flexGrow: 1 }}>
                                              <Typography
                                                variant="body1"
                                                sx={{ mb: 1 }}
                                              >
                                                <MathJax dynamic>
                                                  {
                                                    suggestion.sampleMCQ
                                                      .question
                                                  }
                                                </MathJax>
                                              </Typography>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  gap: 1,
                                                }}
                                              >
                                                {suggestion.sampleMCQ.options.map(
                                                  (option, i) => {
                                                    const isSelected =
                                                      selectedAnswer === option;
                                                    const showCorrect =
                                                      selectedAnswer &&
                                                      option ===
                                                        suggestion.sampleMCQ
                                                          .correctAnswer;
                                                    const showIncorrect =
                                                      isSelected && !isCorrect;

                                                    return (
                                                      <Typography
                                                        key={i}
                                                        sx={{
                                                          border: 2,
                                                          borderColor:
                                                            showCorrect
                                                              ? "success.main"
                                                              : showIncorrect
                                                                ? "error.main"
                                                                : "inherit",
                                                          color: showCorrect
                                                            ? "success.main"
                                                            : showIncorrect
                                                              ? "error.main"
                                                              : "inherit",
                                                          borderWidth: 2,
                                                          padding: "8px 16px",
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          alignItems: "center",
                                                          cursor: "pointer",
                                                          borderRadius: 1,
                                                          transition:
                                                            "background-color 0.2s ease",
                                                          "&:hover": {
                                                            backgroundColor:
                                                              showCorrect
                                                                ? "success.light"
                                                                : showIncorrect
                                                                  ? "error.light"
                                                                  : "action.hover",
                                                          },
                                                        }}
                                                        onClick={() =>
                                                          handleAnswerSelect(
                                                            `${index}-${suggestionIndex}`,
                                                            option,
                                                          )
                                                        }
                                                      >
                                                        <MathJax dynamic>
                                                          {option}
                                                        </MathJax>
                                                        {showCorrect && (
                                                          <CheckIcon />
                                                        )}
                                                      </Typography>
                                                    );
                                                  },
                                                )}
                                              </Box>
                                              {selectedAnswer && (
                                                <Typography
                                                  variant="body2"
                                                  color="textSecondary"
                                                  sx={{ mt: 1 }}
                                                >
                                                  Correct Answer:{" "}
                                                  <strong>
                                                    <MathJax dynamic>
                                                      {
                                                        suggestion.sampleMCQ
                                                          .correctAnswer
                                                      }
                                                    </MathJax>
                                                  </strong>
                                                  Explanation:{" "}
                                                  <strong>
                                                    <MathJax dynamic>
                                                      {
                                                        suggestion.sampleMCQ
                                                          .explanation
                                                      }
                                                    </MathJax>
                                                  </strong>
                                                </Typography>
                                              )}
                                            </Box>
                                          )}

                                          <Button
                                            onClick={() =>
                                              handleAddToQuiz(suggestion)
                                            }
                                            sx={{
                                              width: "100%",
                                              mt: "auto",
                                              padding: "4px 8px",
                                              fontSize: "0.875rem",
                                              textTransform: "none",
                                            }}
                                          >
                                            Add to Quiz
                                          </Button>
                                        </CardContent>
                                      </Card>
                                    </motion.div>
                                  );
                                },
                              )}
                            </Box>
                          </Box>
                        ))}
                    </>
                  )
                )}
              </Box>
            </Drawer>
          </>
        )}
      </Box>
    </MathJaxContext>
  );
};

export default Create;
