// Sports & Physical Education - list of subtopics and related MCQs
export const sportsTopics = [
  {
    suggestion: "Understand the basics of physical fitness",
    sampleMCQ: {
      question:
        "Which component of fitness is improved by aerobic exercises like running and swimming?",
      options: [
        "Flexibility",
        "Muscular strength",
        "Cardiovascular endurance",
        "Coordination",
      ],
      correctAnswer: "Cardiovascular endurance",
      explanation:
        "Cardiovascular endurance is improved by aerobic exercises such as running, swimming, and cycling, which enhance the efficiency of the heart, lungs, and blood vessels in delivering oxygen to the body.",
    },
  },
  {
    suggestion: "Learn about the principles of strength training",
    sampleMCQ: {
      question:
        "Which type of exercise is primarily used to build muscle strength?",
      options: ["Stretching", "Resistance training", "Yoga", "Pilates"],
      correctAnswer: "Resistance training",
      explanation:
        "Resistance training, such as weight lifting and bodyweight exercises, is used to build muscle strength by challenging the muscles with resistance.",
    },
  },
  {
    suggestion: "Explore the importance of flexibility in sports",
    sampleMCQ: {
      question: "Which exercise is most effective for improving flexibility?",
      options: ["Running", "Weight lifting", "Stretching", "Cycling"],
      correctAnswer: "Stretching",
      explanation:
        "Stretching exercises are the most effective way to improve flexibility, as they help lengthen muscles and increase the range of motion in joints.",
    },
  },
  {
    suggestion: "Understand the role of nutrition in athletic performance",
    sampleMCQ: {
      question:
        "Which macronutrient is the primary source of energy for athletes?",
      options: ["Fats", "Proteins", "Carbohydrates", "Vitamins"],
      correctAnswer: "Carbohydrates",
      explanation:
        "Carbohydrates are the primary source of energy for athletes because they are quickly converted into glucose, which fuels the muscles during physical activity.",
    },
  },
  {
    suggestion: "Learn about different types of sports injuries",
    sampleMCQ: {
      question:
        "Which of the following is an example of a chronic sports injury?",
      options: ["Fracture", "Sprain", "Tennis elbow", "Dislocation"],
      correctAnswer: "Tennis elbow",
      explanation:
        "Tennis elbow is a chronic sports injury caused by repetitive use of the arm and wrist muscles. Unlike acute injuries, chronic injuries develop over time.",
    },
  },
  {
    suggestion: "Explore the rules and regulations of popular sports",
    sampleMCQ: {
      question:
        "In soccer, how many players are on the field for each team during a match?",
      options: ["9", "10", "11", "12"],
      correctAnswer: "11",
      explanation:
        "In soccer, each team has 11 players on the field during a match, including the goalkeeper. The game is played with two teams of 11 players each.",
    },
  },
  {
    suggestion: "Understand the concept of sportsmanship and fair play",
    sampleMCQ: {
      question: "Which of the following is an example of good sportsmanship?",
      options: [
        "Arguing with the referee",
        "Cheering for your teammates",
        "Blaming teammates for a loss",
        "Ignoring the rules",
      ],
      correctAnswer: "Cheering for your teammates",
      explanation:
        "Good sportsmanship involves showing respect for teammates, opponents, and officials. Cheering for your teammates is an example of encouraging fair play and positive behavior.",
    },
  },
  {
    suggestion: "Learn about the psychological aspects of sports performance",
    sampleMCQ: {
      question:
        "Which term refers to an athlete’s ability to focus and remain calm under pressure?",
      options: ["Agility", "Mental toughness", "Endurance", "Coordination"],
      correctAnswer: "Mental toughness",
      explanation:
        "Mental toughness refers to an athlete's ability to focus, stay calm, and perform effectively under pressure, even in challenging or high-stakes situations.",
    },
  },
  {
    suggestion: "Explore the benefits of team sports",
    sampleMCQ: {
      question:
        "Which of the following is a key benefit of participating in team sports?",
      options: [
        "Improved self-reliance",
        "Increased social interaction and teamwork",
        "Less physical exertion",
        "Reduced need for practice",
      ],
      correctAnswer: "Increased social interaction and teamwork",
      explanation:
        "Participating in team sports helps improve social interaction, communication, and teamwork skills, as players must work together to achieve common goals.",
    },
  },
  {
    suggestion: "Understand the fundamentals of sports biomechanics",
    sampleMCQ: {
      question:
        "Which aspect of sports biomechanics studies the motion of the human body?",
      options: ["Kinematics", "Dynamics", "Statics", "Energy transfer"],
      correctAnswer: "Kinematics",
      explanation:
        "Kinematics is the branch of biomechanics that studies the motion of the human body, focusing on aspects like velocity, acceleration, and joint movements.",
    },
  },
  {
    suggestion: "Learn about hydration and its role in athletic performance",
    sampleMCQ: {
      question:
        "Which is the most important fluid for maintaining hydration during sports activities?",
      options: ["Water", "Soda", "Energy drinks", "Coffee"],
      correctAnswer: "Water",
      explanation:
        "Water is the most important fluid for maintaining hydration during sports activities, as it helps regulate body temperature, replace lost fluids, and prevent dehydration.",
    },
  },
  {
    suggestion: "Explore the role of coaches in sports development",
    sampleMCQ: {
      question:
        "Which of the following is a key responsibility of a sports coach?",
      options: [
        "Only managing the team's finances",
        "Developing strategies and motivating players",
        "Playing on the field with the team",
        "Determining ticket prices for events",
      ],
      correctAnswer: "Developing strategies and motivating players",
      explanation:
        "A sports coach is responsible for developing strategies, guiding athletes, and motivating players to improve their skills and achieve their goals.",
    },
  },
  {
    suggestion: "Understand the importance of warm-up and cool-down exercises",
    sampleMCQ: {
      question:
        "Which of the following is a benefit of a proper warm-up before exercise?",
      options: [
        "Increases muscle stiffness",
        "Decreases body temperature",
        "Reduces the risk of injury",
        "Slows down heart rate",
      ],
      correctAnswer: "Reduces the risk of injury",
      explanation:
        "A proper warm-up increases blood flow to the muscles, enhances flexibility, and reduces the risk of injury by preparing the body for more intense physical activity.",
    },
  },
  {
    suggestion: "Learn about the history and evolution of the Olympic Games",
    sampleMCQ: {
      question: "In which year did the modern Olympic Games begin?",
      options: ["1896", "1900", "1920", "1948"],
      correctAnswer: "1896",
      explanation:
        "The modern Olympic Games began in 1896 in Athens, Greece, and have since grown into one of the largest international sporting events in the world.",
    },
  },
  {
    suggestion: "Explore the impact of technology on sports performance",
    sampleMCQ: {
      question:
        "Which technology is commonly used to review decisions in professional sports?",
      options: [
        "Instant replay",
        "Digital scoreboards",
        "Smartphones",
        "Heart rate monitors",
      ],
      correctAnswer: "Instant replay",
      explanation:
        "Instant replay technology is commonly used in professional sports to review close or controversial decisions, allowing referees to make more accurate calls.",
    },
  },
];
